import { Box } from "@mui/material";
import CartMB from "./CartMB";
const Cart = ({ stateCart, TriggerRefreshCart }) => {
  const Emails = ["username@gmail.com", "user02@gmail.com"];
  return (
    <>
      <Box height={"90vh"} overflow={"scroll"}>
        {/* <CartPC stateCustomer={Emails}></CartPC> */}
        <CartMB
          stateCart={stateCart}
          TriggerRefreshCart={TriggerRefreshCart}
          stateCustomer={Emails}
        ></CartMB>
      </Box>
    </>
  );
};

export default Cart;
