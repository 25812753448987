import { Box, Breadcrumbs } from "@mui/material";
import styled from "styled-components";

import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Theme } from "../theme";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export const MediaCard = ({ img, Title, content, ButtonContactText }) => {
  return (
    <Card
      sx={{
        height: "367px",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between",
        boxShadow: "none",

        // borderWidth: "1px",
        // borderColor: "black",
      }}
    >
      <CardMedia sx={{ height: 140 }} image={img} title="green iguana" />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          sx={{
            color: Theme.designColors.Text,
            fontSize: Theme.designColors.SUBTITLESIZE,
            fontWeight: "700",
          }}
          component="div"
        >
          {Title}
        </Typography>
        <Typography
          sx={{
            color: Theme.designColors.Text,
            fontSize: Theme.designColors.SUBTITLESIZE2,
          }}
          variant="body2"
        >
          {content}
        </Typography>
      </CardContent>
      {ButtonContactText ? (
        <CardActions>
          <Button
            sx={{
              padding: "0.5rem 1rem",
              color: Theme.designColors.Text,
              background: `linear-gradient(to right, ${Theme.designColors.PrimaryBrand1} 65%, ${Theme.designColors.SecondaryBrand1})`,
              backgroundSize: "200% 100%",
              transition: "background-position 0.5s ease",
              "&:hover": {
                backgroundPosition: "100% 0",
                color: "white",
              },
            }}
            size="small"
          >
            {ButtonContactText}
            <ArrowRightAltIcon
              style={{ marginLeft: "1rem" }}
            ></ArrowRightAltIcon>
          </Button>
        </CardActions>
      ) : (
        ""
      )}
    </Card>
  );
};

export const BreadcrumbsCustom = ({ ListBreed = [] }) => {
  let nav = useNavigate();
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      className=" justify-center p-6 bg-gray-300"
      sx={{
        "& .MuiBreadcrumbs-ol": {
          justifyContent: "center",
        },
      }}
    >
      {ListBreed &&
        ListBreed.map((item, index) => (
          <Box>
            {" "}
            {index == ListBreed.length - 1 ? (
              <Typography
                sx={{
                  color: Theme.designColors.PrimaryBrand5,
                  cursor: item?.nav ? "pointer" : "",

                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={() => {
                  nav(item?.nav);
                }}
              >
                {item?.name}
              </Typography>
            ) : (
              <Typography
                sx={{
                  cursor: item?.nav ? "pointer" : "",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={() => {
                  nav(item?.nav);
                }}
              >
                {item?.name}
              </Typography>
            )}
          </Box>
        ))}
      {/* <Typography
          sx={{ cursor: "pointer" }}
          onClick={() => {
            nav("/");
          }}
        >
          Trang chủ
        </Typography>
        <Typography>Chi tiết sản phẩm</Typography>
        <Typography sx={{ color: Theme.designColors.PrimaryBrand5 }}>
          {stateProduct?.name}
        </Typography> */}
    </Breadcrumbs>
  );
};

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const FormBase = ({ children }) => {
  return (
    <>
      <AppContainer>
        <Box
          sx={{
            width: "480px",
            maxWidth: "1320px",
            height: "100%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            // backgroundColor: "blueviolet",
          }}
        >
          {children}
        </Box>
      </AppContainer>
    </>
  );
};
