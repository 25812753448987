import Navbar from "../Navbar/navbar";
import React, { useEffect } from "react";
import { useState } from "react";
import { Box } from "@mui/material";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FooterNav from "../FooterNav/FooterNav";
import Footer from "../Footer/footer";
import { ReactNotifications } from "react-notifications-component";

import { Theme } from "../../theme";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GET_ALL_CATEGORY } from "../../services/handleCategory/handleCategories";
import { GET_ME, LOG_OUT } from "../../services/handleAccount/handleAccount";
import { GET_CART } from "../../services/handlePurchase/handlePurchase";
import { GET_ALL_PROVIDER } from "../../services/handleProvider/handleProvider";
import { RF_TOKEN_F } from "../../services/refreshToken/refreshToken";

const AppContainer = styled.div`
  background-color: ${Theme.BackGround.gray};
  width: 100%;
  height: auto; /* Đảm bảo chiếm toàn bộ chiều cao màn hình */
`;
const Base = ({ children }) => {
  let Nav = useNavigate();
  const [stateCategory, setStateCategory] = useState([]);
  const [stateAccount, setStateAccount] = useState({});
  const [dataFromProduct, setDataFromProduct] = useState(null);
  const [loadingRedirect, setloadingRedirect] = useState(false);
  const [stateProvider, setStateProvider] = useState([]);
  const [stateCart, setStateCart] = useState([]);

  const handleDataFromProduct = (data) => {
    setDataFromProduct(data);
  };
  const MutationRF = useMutation({
    mutationKey: ["Start"],
    mutationFn: (body) => {
      return RF_TOKEN_F(body);
    },
  });
  const MutationLogout = useMutation({
    mutationKey: ["log_out"],
    mutationFn: (body) => {
      return LOG_OUT(body);
    },
  });
  const DataAllProvider = useQuery({
    queryKey: ["GetAllProvider"],
    queryFn: async () => {
      const result = await GET_ALL_PROVIDER();
      if (result?.data?.data) {
        setStateProvider(result?.data?.data);
      }
      return result;
    },
  });
  const DataAllCategory = useQuery({
    queryKey: ["GetAllCategory"],
    queryFn: async () => {
      const result = await GET_ALL_CATEGORY();
      if (result?.data?.data) {
        setStateCategory(result?.data?.data);
      }
      return result;
    },
  });
  const DATA_GET_ME = useQuery({
    queryKey: ["GET_ME"],
    queryFn: async () => {
      const result = await GET_ME();
      if (result?.data?.data) {
        setStateAccount(result?.data?.data);
      } else {
        setStateAccount({});
      }

      return result;
    },
  });
  const DATA_GET_CART = useQuery({
    queryKey: ["GET_CART"],
    queryFn: async () => {
      const result = await GET_CART();
      if (result?.data?.data) {
        setStateCart(result?.data?.data);
      }
      return result;
    },
  });
  const TriggerSetStateAccount = () => {
    setStateAccount(null);
  };

  const TriggerRefreshCart = () => {
    console.log("T");
    DATA_GET_CART.refetch();
  };

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      sendDataToBase: handleDataFromProduct,
      stateCategory: stateCategory,
      stateProvider: stateProvider,
      isloadingCategory: DataAllCategory?.isFetching,
      isloadingProvider: DataAllProvider?.isFetching,
      TriggerRefreshCart: TriggerRefreshCart,
      stateCart: stateCart,
    })
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AppContainer>
        <ReactNotifications></ReactNotifications>
        <Navbar
          onOrderedQuant={dataFromProduct?.onOrderedQuant || 0}
          onReset={dataFromProduct?.onReset || null}
          stateAccount={stateAccount}
          stateCart={stateCart}
          TriggerSetStateAccount={TriggerSetStateAccount}
        ></Navbar>
        <Box
          // pl={2}
          // pr={2}
          sx={{
            // height: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "calc(100vh - 64px)",
            overflow: "auto",
            gap: 2,
          }}
        >
          <Box className="col-xs-12">{childrenWithProps}</Box>

          <Box className="col-xs-12">
            {" "}
            <Footer stateCategory={stateCategory}></Footer>
          </Box>
        </Box>
      </AppContainer>
    </>
  );
};

export default Base;
