import styled from "styled-components";
import AccountBox from "../AccountBox/index";
import { Theme } from "../../theme";
import { Box } from "@mui/material";

const Auth = ({ check }) => {
  return (
    <>
      <AccountBox check={check} />
    </>
  );
};

export default Auth;
