import { MockDataIMGTWO } from "../../../data/data";
import { Carousel } from "flowbite-react";
const CarouselProduct = () => {
  return (
    <>
      <div className="h-[190px] sm:h-64 lg:h-64 xl:h-[800px] 2xl:h-[1000px]">
        <Carousel style={{ borderRadius: "0px" }}>
          {MockDataIMGTWO.map((item, index) => (
            <img src={item} alt="..." />
          ))}
        </Carousel>
      </div>
    </>
  );
};
export default CarouselProduct;
