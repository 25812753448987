import LOGO from "../../assets/logo/logo1.png";
import { Theme } from "../../theme";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import LOGONEW from "../../assets/logo/logoHome.jpg";
const Footer = ({ stateCategory }) => {
  return (
    <>
      <hr></hr>
      <footer
        style={{
          height: "auto",

          // marginTop: "3rem",
          // borderWidth: "1px",
        }}
        className="pt-28 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 pl-4 pr-4"
      >
        <div>
          <img style={{ mixBlendMode: "multiply" }} src={LOGO}></img>
          <ul style={{ color: Theme.designColors.Text }}>
            <li>
              <FmdGoodOutlinedIcon
                fontSize="12px"
                sx={{
                  color: Theme.designColors.PrimaryBrand1,
                  marginRight: "3px",
                }}
              ></FmdGoodOutlinedIcon>{" "}
              <span style={{ fontWeight: 700 }}>Địa chỉ</span>: Số 25, Đường 11,
              Phường an phú, Thành Phố Thủ Đức
            </li>
            <li>
              {" "}
              <HeadphonesOutlinedIcon
                fontSize="12px"
                sx={{
                  color: Theme.designColors.PrimaryBrand1,
                  marginRight: "3px",
                }}
              ></HeadphonesOutlinedIcon>
              <span style={{ fontWeight: 700 }}>Liên hệ</span>: 098934944
            </li>

            <li>
              <EmailOutlinedIcon
                fontSize="12px"
                sx={{
                  color: Theme.designColors.PrimaryBrand1,
                  marginRight: "3px",
                }}
              ></EmailOutlinedIcon>
              <span style={{ fontWeight: 700 }}>Email</span>:
              tikpiivietnam@gmail.com
            </li>
            <li>
              <AccessTimeOutlinedIcon
                fontSize="12px"
                sx={{
                  color: Theme.designColors.PrimaryBrand1,
                  marginRight: "3px",
                }}
              ></AccessTimeOutlinedIcon>
              <span style={{ fontWeight: 700 }}>Giờ</span>: 09:00 - 17:00, Thứ 2
              - Thứ 6
            </li>

            <li style={{ fontSize: "1.3rem" }} className="cssforIconFooter">
              <i
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/phototimevn?locale=vi_VN"
                  );
                }}
                class="fa-brands fa-facebook"
              ></i>
              <i
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/phototimeofficial/?fbclid=IwAR08kQKiTbQrt5Yx3UtqUE6V9WtW8U6goh959150_dBEdZjCmHHZGtL5KWM"
                  );
                }}
                class="fa-brands fa-instagram"
              ></i>
              <i
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://www.tiktok.com/@phototimevietnam?fbclid=IwAR2BcmN36mg9o1kgMDgykDKZ3ESZp9ZSMwddeoTWzAt_9VujnoURe0mIq7s"
                  );
                }}
                class="fa-brands fa-tiktok"
              ></i>
            </li>
          </ul>
        </div>
        <div>
          <h2>Dịch vụ khách hàng</h2>
          <ul>
            <li>Hướng dẫn đặt hàng</li>
            <li>Chính sách kiểm hàng</li>

            <li>Chính sách thanh toán & giao hàng</li>
            <li>Chính sách bảo mật</li>
            <li>Điều khoản sử dụng</li>
            <li>Chính sách đổi trả hàng</li>
            <li>Phản ánh khiếu nại về chất lượng</li>

            <li style={{ fontSize: "1.3rem" }} className="cssforIconFooter">
              <i
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/phototimevn?locale=vi_VN"
                  );
                }}
                class="fa-brands fa-facebook"
              ></i>
              <i
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/phototimeofficial/?fbclid=IwAR08kQKiTbQrt5Yx3UtqUE6V9WtW8U6goh959150_dBEdZjCmHHZGtL5KWM"
                  );
                }}
                class="fa-brands fa-instagram"
              ></i>
              <i
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://www.tiktok.com/@phototimevietnam?fbclid=IwAR2BcmN36mg9o1kgMDgykDKZ3ESZp9ZSMwddeoTWzAt_9VujnoURe0mIq7s"
                  );
                }}
                class="fa-brands fa-tiktok"
              ></i>
            </li>
          </ul>
        </div>

        <div>
          <h2>Danh mục sản phẩm</h2>
          <ul>
            {stateCategory &&
              stateCategory.map((item, index) => <li>{item?.name}</li>)}
          </ul>
        </div>
        <div className="flex justify-center">
          <img width={300} src={LOGONEW}></img>
        </div>
        <hr></hr>
      </footer>
    </>
  );
};
export default Footer;
