import { Paper, TextField } from "@mui/material";
import { BreadcrumbsCustom } from "../../component/component";
import { styled } from "@mui/material/styles";
import {
  IconLocation,
  IconFacebook,
  IconYoutube,
  IconInstagram,
  IconTwitter,
} from "../../assets/Icon/Icon";
import { useState } from "react";
const Contact = () => {
  let navCustomBread = [
    {
      nav: "/",
      name: "Trang chủ",
    },
    {
      nav: "/contact",
      name: "Liên hệ",
    },
  ];
  const [stateForm, setStateForm] = useState({
    name: "",
    phone: 0,
    email: "",
    message: "",
  });
  const [stateError, setStateError] = useState({});
  const validate = () => {
    const isValidPhoneNumber = (phoneNumber) => {
      // Biểu thức chính quy để kiểm tra số điện thoại
      const phoneRegex = /^(?:\+?84|0)(?:[3|5|7|8|9]\d{8})$/;
      return phoneRegex.test(phoneNumber);
    };
    const isValidEmail = (email) => {
      // Biểu thức chính quy để kiểm tra email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const newErrors = {};
    if (!stateForm.name) {
      newErrors.name = "Vui lòng không bỏ trống thông tin !!";
    }
    if (!stateForm.phone) {
      newErrors.phone = "Vui lòng không bỏ trống thông tin !!";
    } else {
      if (!isValidPhoneNumber(stateForm.phone)) {
        newErrors.phone = "Số điện thoại không hợp lệ !!";
      }
    }

    if (!stateForm.email) {
      newErrors.email = "Vui lòng không bỏ trống thông tin !!";
    } else {
      if (!isValidEmail(stateForm.email)) {
        newErrors.email = "Email không hợp lệ !!";
      }
    }
    setStateError(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const HandleSendForm = () => {
    if (validate()) {
      alert("Gửi thông tin thành công");
      window.location.reload();
    }
  };
  const HandleChange = (e) => {
    setStateError({});
    setStateForm({
      ...stateForm,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <>
      <BreadcrumbsCustom ListBreed={navCustomBread}></BreadcrumbsCustom>
      <div className="container">
        <Paper
          elevation={2}
          className=" grid grid-cols-4 p-4 gap-16 w-full max-w-full"
        >
          <div className="col-span-4 xl:col-span-2 flex flex-col gap-4">
            <span className="font-normal text-xl">
              Hãy gửi liên hệ cho chúng tôi
            </span>
            <TextField
              error={stateError?.name ? true : false}
              label={"Họ và Tên"}
              variant="filled"
              type="text"
              name="name"
              value={stateForm.name}
              onChange={HandleChange}
              helperText={stateError?.name ? stateError?.name : ""}
            />

            <TextField
              InputProps={{
                inputProps: {
                  style: { MozAppearance: "textfield" }, // Loại bỏ mũi tên trong Firefox
                },
              }}
              sx={{
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none", // Loại bỏ mũi tên trong Chrome/Safari
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield", // Loại bỏ mũi tên trong Firefox
                },
              }}
              error={stateError?.phone ? true : false}
              label={"Số điện thoại"}
              variant="filled"
              type="number"
              name="phone"
              value={stateForm.phone}
              onChange={HandleChange}
              helperText={stateError?.phone ? stateError?.phone : ""}
            />
            <TextField
              error={stateError?.email ? true : false}
              label={"Email"}
              variant="filled"
              type="email"
              name="email"
              value={stateForm.email}
              onChange={HandleChange}
              helperText={stateError?.email ? stateError?.email : ""}
            />
            <textarea
              id="message"
              rows={"10"}
              cols={"10"}
              class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Lời nhắn"
            ></textarea>
            <button
              onClick={HandleSendForm}
              className="w-40 bg-[#FFA708] p-2 font-bold text-xl text-white"
            >
              GỬI
            </button>
          </div>

          <div className="col-span-4 xl:col-span-2  flex flex-col gap-4">
            <span className="font-semibold text-[22px]">
              CÔNG TY TNHH THƯƠNG MẠI DỊCH VỤ TIKPII TECHNOLOGY
            </span>
            <span className="flex justify-start items-center gap-4">
              <IconLocation></IconLocation>
              <span className="text-base">
                25 Đ. Số 11, Khu đô thị An Phú An Khánh, Quận 2, Hồ Chí Minh
                70000, Việt Nam
              </span>
            </span>
            <span className="flex justify-start items-center gap-4">
              <svg
                className="w-[23px] h-[23px] text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                  d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z"
                />
              </svg>

              <span className="text-base">0904 610 817</span>
            </span>
            <span className="flex justify-start items-center gap-4">
              <svg
                className="w-[22px] h-[22px] text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                  d="M11 16v-5.5A3.5 3.5 0 0 0 7.5 7m3.5 9H4v-5.5A3.5 3.5 0 0 1 7.5 7m3.5 9v4M7.5 7H14m0 0V4h2.5M14 7v3m-3.5 6H20v-6a3 3 0 0 0-3-3m-2 9v4m-8-6.5h1"
                />
              </svg>

              <span className="text-base">tikpiivietnam@gmail.com</span>
            </span>
            <span className="flex justify-start items-center gap-4">
              <svg
                className="w-[22px] h-[22px] text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeWidth="1"
                  d="M4.37 7.657c2.063.528 2.396 2.806 3.202 3.87 1.07 1.413 2.075 1.228 3.192 2.644 1.805 2.289 1.312 5.705 1.312 6.705M20 15h-1a4 4 0 0 0-4 4v1M8.587 3.992c0 .822.112 1.886 1.515 2.58 1.402.693 2.918.351 2.918 2.334 0 .276 0 2.008 1.972 2.008 2.026.031 2.026-1.678 2.026-2.008 0-.65.527-.9 1.177-.9H20M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>

              <span className="text-base">tikpii.com</span>
            </span>
            <span className="flex justify-start items-center gap-4">
              <IconYoutube></IconYoutube>

              <IconInstagram></IconInstagram>
              <IconFacebook></IconFacebook>
              <IconTwitter></IconTwitter>
            </span>

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15677.055118087745!2d106.7367616!3d10.7910979!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175272c549d274f%3A0x7d2cd1b70c4ffbb4!2sTikpii%20Technology!5e0!3m2!1sen!2s!4v1733902442032!5m2!1sen!2s"
              style={{
                border: "0px",
                width: "100%",
                maxWidth: "100%",
                height: "450px",
              }}
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>
        </Paper>
      </div>
    </>
  );
};
export default Contact;
