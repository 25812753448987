import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Pagination,
  Rating,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import CarouselProduct from "./component/component";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ICONTUVAN from "../../assets/Icon Tikpii/Vector.svg";
import ICONHCH from "../../assets/Icon Tikpii/Vector (1).svg";
import ICONTRALAI from "../../assets/Icon Tikpii/Vector (2).svg";
import { useState } from "react";
import { GET_ALL_PRODUCT } from "../../services/handleProduct/handleProduct";
import { useQuery } from "@tanstack/react-query";
import { Theme } from "../../theme";
import { TitleProduct } from "../../component/Styles/Title";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MediaCard } from "../../component/component";
const Test = () => {
  let nav = useNavigate();
  const [stateProduct, setStateProduct] = useState([]);
  const [statePage, setStatePage] = useState(1);
  const [stateLimit, setStateLimit] = useState(15);
  const [stateCount, setStateCount] = useState(0);
  const IMG1 =
    "https://res.cloudinary.com/dhscgjuis/image/upload/v1726200910/TikpiiMall/Blog/mpcvs7jlcbds96nabown.svg";
  const IMG2 =
    "https://res.cloudinary.com/dhscgjuis/image/upload/v1726200909/TikpiiMall/Blog/szw5kgxtzfrw742c8yud.svg";
  const IMG3 =
    "https://res.cloudinary.com/dhscgjuis/image/upload/v1726200909/TikpiiMall/Blog/ygfstqikq3cazsxmhd6b.svg";

  const DataAllProduct = useQuery({
    queryKey: ["GetAllProduct", statePage],
    queryFn: async () => {
      const result = await GET_ALL_PRODUCT({
        page: statePage,
        limit: stateLimit,
      });
      if (result?.data?.data) {
        setStateCount(result?.data?.data?.total_page);
        setStateProduct(result?.data?.data?.products);
      }
      return result;
    },
  });
  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#ff6d75",
    },
    "& .MuiRating-iconHover": {
      color: "#ff3d47",
    },
  });
  const handleChange = (event, value) => {
    setStatePage(value);
  };
  return (
    <Box className="flex flex-col">
      <CarouselProduct></CarouselProduct>;
      <Box className="mt-6 p-0 container flex justify-between gap-4 flex-col sm:flex-col md:flex-col lg:flex-row">
        <Box className="flex-1 gap-4 flex justify-center items-center">
          <Box>
            <img width={34} height={34} src={ICONTUVAN}></img>
          </Box>
          <Box className="flex flex-col justify-between">
            <span className="text-lg text-black  font-semibold">
              TƯ VẤN & CHĂM SÓC 1 KÈM 1
            </span>
            <span className="text-sm text-gray-600 font-normal">
              Trong suốt quá trình sử dụng
            </span>
          </Box>
        </Box>
        <Box className="flex-1 gap-4 flex justify-center items-center">
          <Box>
            <img width={34} height={34} src={ICONHCH}></img>
          </Box>
          <Box className="flex flex-col justify-between">
            <span className="text-lg text-black  font-semibold">
              SẢN PHẨM 100% CHÍNH HÃNG
            </span>
            <span className="text-sm text-gray-600 font-normal">
              Cam kết và đảm bảo về nguồn gốc
            </span>
          </Box>
        </Box>
        <Box className="flex-1 gap-4 flex justify-center items-center">
          <Box>
            <img width={34} height={34} src={ICONTRALAI}></img>
          </Box>
          <Box className="flex flex-col justify-between">
            <span className="text-lg text-black  font-semibold">
              CHO PHÉP TRẢ HÀNG
            </span>
            <span className="text-sm text-gray-600 font-normal">
              Cho phép đổi trả trong vòng 15 ngày
            </span>
          </Box>
        </Box>
      </Box>
      <Box className="flex container p-0 mt-10 gap-2 flex-col">
        <span className="text-black text-2xl font-semibold">
          Sản phẩm tiêu biểu
        </span>
        <div
          style={{ width: "100%" }}
          className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-1"
        >
          {DataAllProduct?.isFetching ? (
            <>
              {" "}
              {[...Array(5)].map((_, index) => (
                <div>
                  <Skeleton variant="rounded" height={250} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </div>
              ))}
            </>
          ) : (
            <>
              {" "}
              {stateProduct &&
                stateProduct.map((item, index) => (
                  <Card
                    sx={{
                      borderRadius: "10px",
                      position: "relative",
                      boxShadow: "none",
                      // display: "inline-block",
                    }}
                    onClick={() => {
                      nav(`/pDetails/${item?._id}`, {
                        state: item?.category?._id,
                      });
                    }}
                  >
                    <CardActionArea
                      sx={{ minHeight: "350px", height: "270px" }}
                    >
                      <CardMedia
                        component="img"
                        sx={{
                          padding: "0px 10px",
                          minHeight: "125px",
                          height: "125px",
                        }}
                        image={item?.images[0]?.path}
                        alt="green iguana"
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          fontSize={Theme.designColors.SUBTITLESIZE}
                          component="div"
                        >
                          <TitleProduct> {item?.name}</TitleProduct>
                        </Typography>
                        <Box display={"flex"} gap={1} alignItems={"center"}>
                          <Typography variant="body2" color="text.secondary">
                            Giá:{" "}
                          </Typography>
                          <Typography
                            fontSize={Theme.designColors.SUBTITLESIZE2}
                            fontWeight={600}
                            color={Theme.designColors.PrimaryBrand1}
                          >
                            {" "}
                            {parseInt(item?.price_retail).toLocaleString(
                              "en-US"
                            )}{" "}
                            VND
                          </Typography>
                        </Box>
                        <Box display={"flex"} gap={1} alignItems={"center"}>
                          <Typography variant="body2" color="text.secondary">
                            Tồn kho:{" "}
                          </Typography>
                          <Typography
                            fontSize={Theme.designColors.SUBTITLESIZE2}
                            variant="body2"
                            color="text.secondary"
                          >
                            {" "}
                            {item?.store_company?.stock}
                          </Typography>
                        </Box>

                        <Box
                          className="row"
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <StyledRating
                            className="col-md-6"
                            name="customized-color"
                            defaultValue={5}
                            getLabelText={(value) =>
                              `${value} Heart${value !== 1 ? "s" : ""}`
                            }
                            precision={0.5}
                            icon={<FavoriteIcon fontSize="small" />}
                            emptyIcon={<FavoriteBorderIcon fontSize="small" />}
                          />
                          <Typography
                            className="col-md-6"
                            variant="body2"
                            color="text.secondary"
                          >
                            Đã bán: {item?.sold}
                          </Typography>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        backgroundColor: "rgba(204, 51, 102, 0.5)",

                        color: "white",
                        padding: "10px",
                        borderRadius: "0 0 10px 0",
                      }}
                    >
                      HOT
                    </div>
                  </Card>
                ))}
            </>
          )}
        </div>
        <Box width={"100%"} mt={3}>
          <Stack spacing={2}>
            <span className="text-sm text-black">Trang: {statePage}</span>
            <Pagination
              variant="outlined"
              shape="rounded"
              count={stateCount}
              page={statePage}
              onChange={handleChange}
            />
          </Stack>
        </Box>
      </Box>
      <Box className="flex container p-0 mt-10 gap-4 flex-col">
        <span className="text-black text-2xl font-semibold">
          Kiến thức cần biết
        </span>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          <Box>
            <MediaCard
              img={IMG1}
              Title={"LADY LEADER – THỎI SON KHƠI NGUỒN"}
              content={
                "Lady Leader là bộ sưu tập màu sắc của thương hiệu Mỹ phẩm xanh C’Choi vừa được ra mắt vào mùa hè ..."
              }
              ButtonContactText={"Xem thêm"}
            ></MediaCard>
          </Box>
          <Box>
            <MediaCard
              img={IMG2}
              Title={"ĐIỀU GÌ LÀM NÊN MỘT C’CHOI"}
              content={
                "Hiện nay, C’CHOI đã trở thành thương hiệu Mỹ Phẩm Thiên Nhiên – Mỹ phẩm Xanh được đông đảo ..."
              }
              ButtonContactText={"Xem thêm"}
            ></MediaCard>
          </Box>
          <Box>
            <MediaCard
              img={IMG3}
              Title={"Làm việc ở nhà đừng quên chống nắng"}
              content={
                "Quên trang điểm hay dưỡng một ngày có thể không sao, nhưng quên chống nắng là có sao đấy ..."
              }
              ButtonContactText={"Xem thêm"}
            ></MediaCard>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default Test;
