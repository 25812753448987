export const Theme = {
  color: {
    primary: "#cc3366",
    secondary: "",
    freeship: "#23c6c8",
    title: "#384059",
    des: "#9B9FAC",
  },
  BackGround: {
    gray: "white",
  },
  designColors: {
    PrimaryBrand1: "#FFB021",
    PrimaryBrand2: "#FFC458",
    PrimaryBrand3: "#FFE2AC",
    PrimaryBrand4: "#FFFFFF",
    PrimaryBrand5: "#FFA708",

    SecondaryBrand1: "#0C2DFE",
    SecondaryBrand2: "#EFEFFD",
    SecondaryBrand3: "#2305DE",

    Dark: "#0E0E2C",

    Success1: "#ED4B9E",
    Success2: "#31D0AA",

    Text: "#4A4A68",
    SubtleText: "#8C8CA1",
    Accent: "#ECF1F4",
    Light: "#FAFCFE",

    TITLESIZE: "clamp(16px, 3vw, 24px)",
    SUBTITLESIZE: "clamp(12px, 2vw, 16px)",
    SUBTITLESIZE2: "clamp(10px, 1.5vw, 14px)",
  },
};
