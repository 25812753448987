import api from "../common/apis";
import NProgress from "nprogress";
export const GET_ALL_PROVIDER = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/providers/get-all-providers`);
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const GET_PROVIDER_BY_ID = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/providers/get-provider-by-id/${req}`);
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
