import { React, useState } from "react";
import {
  Alert,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import styled from "styled-components";
import { Theme } from "../../theme";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { HandleLogin } from "../../services/handleAccount/handleAccount";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoginF } from "./LoginForm";
import { RegisterF } from "./RegisterForm";
import { CheckF } from "./CheckForm";
const AccountBox = ({ check }) => {
  let nav = useNavigate();
  const [stateCheck, setStateCheck] = useState("check");
  const [showPassword, setShowPassword] = useState(false);
  const [FormLogin, setFormLogin] = useState({
    phone: "",
    password: "",
  });
  const [isLoading, setIsloading] = useState(false);
  const [DataError, setDataError] = useState({});
  const MutationLogin = useMutation({
    mutationFn: (body) => {
      return HandleLogin(body);
    },
  });
  const onChange = (e) => {
    setFormLogin({
      ...FormLogin,
      [e.target.name]: e.target.value,
    });
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const sLogin = () => {
    setIsloading(true);
    MutationLogin.mutate(FormLogin, {
      onSuccess: (data) => {
        if (data?.errors) {
          setDataError(data);
          setIsloading(false);
        } else {
          localStorage.setItem("token", data.data.data.access_token);
          nav("/product");
        }
      },
      onError: (err) => {
        console.log("lỗi " + err);
        setIsloading(false);
      },
    });
  };

  return (
    <>
      <Box
        style={{
          background: `linear-gradient(to bottom right, ${Theme.designColors.PrimaryBrand2} 10%, ${Theme.designColors.PrimaryBrand4} 80%`,
        }}
        className="w-[100%] flex  h-[100vh] relative"
      >
        <Box className="m-auto gap-72  pl-10 pr-10 grid grid-cols-1 lg:grid-cols-2 items-center">
          <Box className="hidden  lg:flex flex-col justify-start items-start  ">
            <Box className="m-auto">
              <Typography variant="h2" fontWeight={700} color={"#262626"}>
                Xin chào
              </Typography>

              <Typography
                fontSize={Theme.designColors.SUBTITLESIZE2}
                fontWeight={600}
                color={"#262626"}
              >
                Nền tảng thương mại điện tử cho sản phẩm tư vấn
              </Typography>
            </Box>
          </Box>
          <Box>
            {stateCheck == "login" ? (
              <LoginF
                FormLogin={FormLogin}
                setFormLogin={setFormLogin}
                setStateCheck={setStateCheck}
              ></LoginF>
            ) : stateCheck == "register" ? (
              <RegisterF
                FormLogin={FormLogin}
                setFormLogin={setFormLogin}
                setStateCheck={setStateCheck}
              ></RegisterF>
            ) : (
              <CheckF
                FormLogin={FormLogin}
                setFormLogin={setFormLogin}
                setStateCheck={setStateCheck}
                isLoading={isLoading}
                DataError={DataError}
                showPassword={showPassword}
                handleClickShowPassword={handleClickShowPassword}
                nav={nav}
              ></CheckF>
            )}
          </Box>
        </Box>
        <Box className="absolute top-10 left-10">
          <IconButton
            onClick={() => {
              nav("/");
            }}
          >
            <ArrowBackIcon fontSize="large"></ArrowBackIcon>
            <span className="text-2xl font-medium">Trở lại</span>
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default AccountBox;
