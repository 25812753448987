import { styled } from "@mui/system";
import "./style.css";
import { Accordion } from "flowbite-react";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import { MenuItem as BaseMenuItem, menuItemClasses } from "@mui/base/MenuItem";
import { IconButton, Skeleton } from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Button, Drawer, Sidebar, TextInput } from "flowbite-react";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#99CCF3",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E6",
  700: "#0059B3",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};
export const MenuItem = styled(BaseMenuItem)(
  ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 8px;
    cursor: default;
    user-select: none;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &:focus {
      outline: 3px solid ${
        theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
      background-color: ${
        theme.palette.mode === "dark" ? grey[800] : grey[100]
      };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
  
    &.${menuItemClasses.disabled} {
      color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
    `
);

export const MenuButton = styled(BaseMenuButton)(
  ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 8px 16px;
    border-radius: 8px;
    color: white;
    transition: all 150ms ease;
    cursor: pointer;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  
    &:hover {
      background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
    }
  
    &:active {
      background: ${theme.palette.mode === "dark" ? grey[700] : grey[100]};
    }
  
    &:focus-visible {
      box-shadow: 0 0 0 4px ${
        theme.palette.mode === "dark" ? blue[300] : blue[200]
      };
      outline: none;
    }
    `
);

export const Listbox = styled("ul")(
  ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 6px;
    margin: 12px 0;
    min-width: 200px;
    border-radius: 12px;
    overflow: auto;
    outline: 0px;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    box-shadow: 0px 4px 30px ${
      theme.palette.mode === "dark" ? grey[900] : grey[200]
    };
    z-index: 1;
  
    .closed & {
      opacity: 0;
      transform: scale(0.95, 0.8);
      transition: opacity 200ms ease-in, transform 200ms ease-in;
    }
    
    .open & {
      opacity: 1;
      transform: scale(1, 1);
      transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
    }
  
    .placement-top & {
      transform-origin: bottom;
    }
  
    .placement-bottom & {
      transform-origin: top;
    }
    `
);
export const ButtonMoveLeft = styled(IconButton)({
  position: "sticky",
  paddingLeft: "1rem",
  // marginTop: "5%",
  left: "4.5%",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 1,
  fontSize: "2rem",
  color: "white",
  backgroundColor: "gray",
  display: "flex",

  opacity: 0.4,
  width: "50px",
  height: "50px",
  borderRadius: "100%",

  "&:hover": {
    backgroundColor: "gray", // Thay đổi màu nền khi hover
  },
});

export const ButtonMoveRight = styled(IconButton)({
  position: "sticky",
  right: "4.5%",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 1,

  fontSize: "2rem",
  color: "white",
  backgroundColor: "gray",
  opacity: 0.4,
  width: "50px",
  height: "50px",
  borderRadius: "100%",
  "&:hover": {
    backgroundColor: "gray", // Thay đổi màu nền khi hover
  },
});

export const ListAccordion = ({
  stateCategory,
  setStateIDCategory,
  setStatePage,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [stateActive, setStateActive] = useState("none");

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const handleGetIdCategory = (x) => {
    setStateIDCategory(x);
    setStatePage(1);
  };
  return (
    <div className="hidden md:block">
      <div
        className="flex justify-between pt-3 cursor-pointer"
        onClick={toggleAccordion}
      >
        <h2 className="text-lg font-semibold">Danh mục</h2>
        {isOpen ? (
          <ExpandLessIcon className="text-gray-600 " />
        ) : (
          <ExpandMoreIcon className="text-gray-600  " />
        )}
      </div>
      <hr></hr>
      <div
        className={`transition-all duration-300 ease-in-out overflow-hidden ${
          isOpen ? "max-h-96" : "max-h-0"
        }`}
      >
        <div className="pt-3 pl-10 flex flex-col gap-4">
          {stateCategory && stateCategory.length > 0 ? (
            stateCategory.map((item, index) => (
              <div
                onClick={() => {
                  handleGetIdCategory(item?._id);
                  setStateActive(index);
                }}
                className="flex gap-2 items-center cursor-pointer hover:underline"
              >
                <img width={20} height={20} src={item?.image}></img>
                <div
                  style={{ color: stateActive == index ? "red" : "" }}
                  className="flex items-center justify-center  h-full"
                >
                  {item?.name}
                </div>
              </div>
            ))
          ) : (
            <>
              {" "}
              <div className="flex gap-2 items-center cursor-pointer hover:underline">
                <Skeleton
                  variant="text"
                  width={"100%"}
                  sx={{ fontSize: "1rem" }}
                />
              </div>
              <div className="flex gap-2 items-center cursor-pointer hover:underline">
                <Skeleton
                  variant="text"
                  width={"100%"}
                  sx={{ fontSize: "1rem" }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const DrawerCategory = ({ stateCategory }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  return (
    <>
      <div className="absolute top-[-5%] left-0 z-10 block md:hidden">
        <div className="flex min-h-[50vh] items-center justify-center">
          <Button onClick={() => setIsOpen(true)}>
            <MenuOutlinedIcon></MenuOutlinedIcon>
          </Button>
        </div>
      </div>
      <Drawer open={isOpen} onClose={handleClose}>
        <Drawer.Header title="DANH MỤC" titleIcon={() => <></>} />
        <Drawer.Items>
          <Sidebar
            aria-label="Sidebar with multi-level dropdown example"
            className="[&>div]:bg-transparent [&>div]:p-0"
          >
            <div className="flex h-full flex-col justify-between py-2">
              <div>
                <Sidebar.Items>
                  <Sidebar.ItemGroup>
                    {stateCategory &&
                      stateCategory.map((item, index) => (
                        <>
                          <Sidebar.Item className="!p-0">
                            {" "}
                            {item?.name}
                          </Sidebar.Item>
                        </>
                      ))}
                  </Sidebar.ItemGroup>
                </Sidebar.Items>
              </div>
            </div>
          </Sidebar>
        </Drawer.Items>
      </Drawer>
    </>
  );
};
