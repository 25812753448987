import Product from "./pages/Product/Product";
import ProductDetails from "./pages/productDetails/ProductDetails";
import Cart from "./pages/Cart/Cart";
import ProductProvider from "./pages/Product/ProductProvier";
import ProductOfCategory from "./pages/Product/ProductCategories";
import Order from "./pages/Order/Order";
import Homepage from "./pages/Homepage";
import Contact from "./pages/Contact/Contact";
export const RoutePublic = [
  { path: "/", element: <Homepage></Homepage> },

  { path: "/cart", element: <Cart></Cart> },
  { path: "/order", element: <Order></Order> },
  { path: "/product", element: <Product></Product> },
  { path: "/pDetails/:productid", element: <ProductDetails></ProductDetails> },
  {
    path: "/pProvider/:providerid",
    element: <ProductProvider></ProductProvider>,
  },
  {
    path: "/pCategory/:categoryid",
    element: <ProductOfCategory></ProductOfCategory>,
  },
  { path: "/product", element: <Product></Product> },
  { path: "/contact", element: <Contact></Contact> },
];
