import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { ButtonSignIn, FormBase } from "./Component/Component";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { HandleRegister } from "../../services/handleAccount/handleAccount";
import { Theme } from "../../theme";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
export const RegisterF = ({ setFormLogin, FormLogin, setStateCheck }) => {
  let nav = useNavigate();
  const [statePassword, setStatePassword] = useState({
    password: "",
    confirm_password: "",
  });
  const [stateError, setStateError] = useState({});
  const [stateShowPassword, setStateShowPassword] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const MutationRegister = useMutation({
    mutationKey: ["HANLDE_REGISTER"],
    mutationFn: (body) => {
      return HandleRegister(body);
    },
  });
  const handleChange = (e) => {
    setStatePassword({
      ...statePassword,
      [e.target.name]: e.target.value,
    });
  };

  const validate = () => {
    const newErrors = {};

    if (!statePassword.password) {
      newErrors.password = "Vui lòng không trống bỏ mật khẩu !!";
    } else {
      if (statePassword.password !== statePassword.confirm_password) {
        newErrors.confirm_password = "Mật khẩu không khớp !!";
      }
    }

    setStateError(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleClickShowPassword = () => {
    setStateShowPassword(!stateShowPassword);
  };
  const handleRegister = () => {
    setStateLoading(true);
    try {
      if (validate()) {
        MutationRegister.mutate(
          {
            phone: FormLogin?.phone,
            password: statePassword?.password,
            confirm_password: statePassword?.confirm_password,
          },
          {
            onSuccess: (data) => {
              console.log("dataa ", JSON.stringify(data));
              if (data?.errors) {
                const newErrors = {};

                newErrors.password = "Mật khẩu phải có ít nhất 6 ký tự !!";

                setStateError(newErrors);
              } else {
                setStateCheck("check");
              }
              setStateLoading(false);
            },
            onError: (err) => {
              console.log("err", err);
              setStateLoading(false);
            },
          }
        );
      } else {
        setStateLoading(false);
      }
    } catch (error) {
      setStateLoading(false);
    }
  };
  return (
    <>
      <Box>
        {" "}
        <Box
          p={3}
          sx={{
            backgroundColor: Theme.designColors.PrimaryBrand3,
            borderRadius: "20px",
            borderColor: Theme.designColors.PrimaryBrand4,
            borderWidth: "1px",
          }}
        >
          <Box
            width={"100px"}
            onClick={() => {
              setStateCheck("check");
            }}
            sx={{ cursor: "pointer" }}
            display={"flex"}
            alignItems={"center"}
            mb={2}
          >
            <ArrowBackOutlinedIcon
            // style={{ marginRight: "5px" }}
            ></ArrowBackOutlinedIcon>

            <Typography
              color={"#393E40"}
              fontWeight={"700"}
              fontSize={"14px"}
              margin={"auto"}
            >
              {" "}
              Quay lại
            </Typography>
          </Box>
          <Box
            mt={4}
            sx={{
              paddingLeft: "2rem",
              paddingRight: "2rem",
              width: "100%",
              height: "62%",
            }}
          >
            <Box mb={2} display={"flex"}>
              <Typography
                color={"#393E40"}
                fontWeight={"700"}
                fontSize={"25px"}
                margin={"auto"}
                style={{ cursor: "pointer" }}
              >
                Tạo mật khẩu
              </Typography>
            </Box>
            <form>
              <Box class="form-group">
                <TextField
                  variant="outlined"
                  type={stateShowPassword ? "text" : "password"}
                  value={statePassword?.password}
                  name="password"
                  onChange={handleChange}
                  placeholder="Mật khẩu"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        sx={{
                          height: "40px",
                          borderRight: "solid 1px ",
                          paddingRight: "10px",
                        }}
                        position="start"
                      >
                        <LockOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {stateShowPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      height: "50px",
                    },
                  }}
                  fullWidth
                  sx={{
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
                    backgroundColor: "white",
                    borderRadius: "20px",
                    height: "50px",
                    cursor: "pointer",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      "& fieldset": {
                        border: "none", // Xóa viền ngoài
                      },
                      "&:hover fieldset": {
                        border: "none", // Xóa viền khi hover
                      },
                      "&.Mui-focused fieldset": {
                        border: "none", // Xóa viền khi có focus
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "12px",

                      height: "40px", // Đảm bảo nội dung và input có chiều cao đồng nhất
                    },
                    "& .MuiInputBase-input": {
                      padding: "10px 12px", // Điều chỉnh padding bên trong để có kích thước phù hợp
                    },
                  }}
                />
              </Box>
              <Box class="form-group">
                <TextField
                  variant="outlined"
                  type={stateShowPassword ? "text" : "password"}
                  value={statePassword?.confirm_password}
                  name="confirm_password"
                  onChange={handleChange}
                  placeholder="Xác nhận Mật khẩu"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        sx={{
                          height: "40px",
                          borderRight: "solid 1px ",
                          paddingRight: "10px",
                        }}
                        position="start"
                      >
                        <LockResetOutlinedIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {stateShowPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      height: "50px",
                    },
                  }}
                  fullWidth
                  sx={{
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
                    backgroundColor: "white",
                    borderRadius: "20px",
                    height: "50px",
                    cursor: "pointer",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      "& fieldset": {
                        border: "none", // Xóa viền ngoài
                      },
                      "&:hover fieldset": {
                        border: "none", // Xóa viền khi hover
                      },
                      "&.Mui-focused fieldset": {
                        border: "none", // Xóa viền khi có focus
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "12px",

                      height: "40px", // Đảm bảo nội dung và input có chiều cao đồng nhất
                    },
                    "& .MuiInputBase-input": {
                      padding: "10px 12px", // Điều chỉnh padding bên trong để có kích thước phù hợp
                    },
                  }}
                />
              </Box>
            </form>
            {stateError && stateError?.password ? (
              <Alert style={{ marginTop: "1rem" }} severity="error">
                {stateError?.password}
              </Alert>
            ) : (
              ""
            )}

            {stateError && stateError?.confirm_password ? (
              <Alert style={{ marginTop: "1rem" }} severity="error">
                {stateError?.confirm_password}
              </Alert>
            ) : (
              ""
            )}

            <Box mt={2} display={"flex"} justifyContent={"end"}>
              <Typography fontWeight={"500"} color={"#393E40"}>
                Quên mật khẩu ?
              </Typography>
            </Box>
            <Box mt={2} display={"flex"} justifyContent={"end"}>
              {stateLoading ? (
                <ButtonSignIn disabled={stateLoading}>
                  {" "}
                  <CircularProgress
                    sx={{ fontSize: "10px" }}
                  ></CircularProgress>
                </ButtonSignIn>
              ) : (
                <ButtonSignIn
                  onClick={() => {
                    handleRegister();
                  }}
                >
                  Đăng nhập
                </ButtonSignIn>
              )}
            </Box>
            <Box mt={2} display={"grid"} justifyContent={"center"}>
              <Typography
                variant={"subtitle2"}
                color={"#5C6366"}
                textAlign={"center"}
              >
                hoặc
              </Typography>

              <Typography
                mt={1}
                fontSize={"14px"}
                fontWeight={"600"}
                color={"#1B3FE4"}
              >
                Đăng nhập bằng số điện thoại
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
