import CartIcon from "./Icons/CartIcon";
import QuantityButton from "./QuantityButton";
import { Box, Rating } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Theme } from "../../../theme";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { NotificationsCustom } from "../../../component/Notifications";
import { Store } from "react-notifications-component";
import { CREATE_CART } from "../../../services/handlePurchase/handlePurchase";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
const Description = ({
  onQuant,
  onAdd,
  onRemove,
  onSetOrderedQuant,
  stateProduct,
  TriggerRefreshCart,
}) => {
  const [Isloading, setIsloading] = useState(false);
  const [IsShowDesc, setIsShowDesc] = useState(false);
  const HANDLE_CREATE_CART = useMutation({
    mutationFn: (body) => {
      return CREATE_CART(body);
    },
  });
  const handleCreateCart = () => {
    if (onQuant <= 0) {
      alert("Vui lòng chọn số lượng sản phẩm !!");
    } else {
      setIsloading(true);
      let FormCreate = {
        purchase_items: [
          {
            product_id: stateProduct?._id,
            buy_count: onQuant,
            store_id: stateProduct?.store_company?.id,
          },
        ],
      };

      HANDLE_CREATE_CART.mutate(FormCreate, {
        onSuccess: (data) => {
          setIsloading(false);
          TriggerRefreshCart();
          if (data?.data) {
            Store.addNotification({
              ...NotificationsCustom({
                title: "Thành công",
                message: "Đã được thêm vào giỏ hàng",
                type: "success",
              }),
              container: "top-right",
            });
          } else {
            Store.addNotification({
              ...NotificationsCustom({
                title: "Thất bại",
                message: "Vui lòng thử lại sau",
                type: "danger",
              }),
              container: "top-right",
            });
          }
        },
        onError: (err) => {
          setIsloading(false);
          TriggerRefreshCart();
          Store.addNotification({
            ...NotificationsCustom({
              title: "Thất bại",
              message: "Vui lòng thử lại sau",
              type: "danger",
            }),
            container: "top-right",
          });

          console.log("on Err " + err);
        },
      });
    }
  };

  return (
    <section className="description">
      <p className="pre">{stateProduct?.provider?.name}</p>
      <Typography
        style={{
          fontSize: Theme.designColors.TITLESIZE,
          fontWeight: 700,
        }}
      >
        {stateProduct && stateProduct?.name}
      </Typography>
      <div className="price">
        <div className="main-tag">
          <p>
            {stateProduct &&
              parseInt(stateProduct?.price_for_customer).toLocaleString(
                "en-US"
              )}{" "}
            đ
          </p>
          <p>{stateProduct && stateProduct?.sales}%</p>
        </div>
      </div>
      <Box>
        {stateProduct && stateProduct?.discount > 0 ? (
          <s>
            {parseInt(stateProduct?.price_for_customer).toLocaleString("en-US")}
          </s>
        ) : (
          ""
        )}

        <Typography className="text-secondary">
          Đã bán{" "}
          {stateProduct && parseInt(stateProduct?.sold).toLocaleString("en-US")}
        </Typography>
      </Box>
      <Rating
        name="half-rating-read"
        defaultValue={parseInt(stateProduct?.avg_rating)}
        precision={0.25}
        readOnly
        sx={{ fontSize: "2rem" }}
      />{" "}
      <p
        style={{
          height: IsShowDesc ? "100%" : "150px",
        }}
        className={`desc ${IsShowDesc ? "" : "ShadowLine"}`}
      >
        {stateProduct && stateProduct?.description}
      </p>
      <Button
        variant="outlined"
        onClick={() => {
          setIsShowDesc(!IsShowDesc);
        }}
        sx={{
          borderColor: "#393E40",
          color: "#393E40",
          borderWidth: "1px",
          borderRadius: "20px",
        }}
      >
        {!IsShowDesc ? (
          <>
            {" "}
            Xem thêm
            <KeyboardArrowDownRoundedIcon
              sx={{ marginLeft: ".5rem" }}
            ></KeyboardArrowDownRoundedIcon>
          </>
        ) : (
          <>
            Thu gọn
            <KeyboardArrowUpRoundedIcon
              sx={{ marginLeft: ".5rem" }}
            ></KeyboardArrowUpRoundedIcon>
          </>
        )}
      </Button>
      <div className="buttons">
        <QuantityButton onQuant={onQuant} onRemove={onRemove} onAdd={onAdd} />
        <button
          className="add-to-cart"
          onClick={() => {
            handleCreateCart();
          }}
        >
          <CartIcon />
          giỏ hàng
        </button>
      </div>
      {/* <Box width={"100%"}>
        <React.Fragment>
          <Button onClick={handleOpen}>Tạo Đơn</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...style, width: 200 }}>
              <h2 id="child-modal-title">Text in a child modal</h2>
              <p id="child-modal-description">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              </p>
              <Button onClick={handleClose}>Thoát</Button>
            </Box>
          </Modal>
        </React.Fragment>
      </Box> */}
    </section>
  );
};

export default Description;
