import React from "react";
import ReactDOM from "react-dom/client";
import "./style.css";
import "../src/output.css";
import App from "./App";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "nprogress/nprogress.css";
import "react-notifications-component/dist/theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import CSS

import reportWebVitals from "./reportWebVitals";
import { HashRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PrimeReactProvider } from "primereact/api";

import "flowbite";
// Create a client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <PrimeReactProvider>
          <App />
        </PrimeReactProvider>
      </QueryClientProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
