import ICON1 from "../assets/Icon Tikpii/camisole- (11).svg";
import ICON2 from "../assets/Icon Tikpii/Cart-Outline.svg";
import ICON3 from "../assets/Icon Tikpii/u_heartbeat.svg";
import ICON4 from "../assets/Icon Tikpii/window-frame.svg";
import ICON5 from "../assets/Icon Tikpii/Son.svg";
import ICON6 from "../assets/Icon Tikpii/Shop.svg";
import ICON7 from "../assets/Icon Tikpii/long-sleeved-shirt (2).svg";
import ICON8 from "../assets/Icon Tikpii/shield-halved.svg";
import ICON9 from "../assets/Icon Tikpii/u_baby-carriage.svg";
import ICON10 from "../assets/Icon Tikpii/t-shirt.svg";
import ICON11 from "../assets/Icon Tikpii/ring-diamond.svg";
import ICON12 from "../assets/Icon Tikpii/Ski.svg";
import ICON13 from "../assets/Icon Tikpii/ticket-discount.svg";
import ICON14 from "../assets/Icon Tikpii/dashboard.svg";
import ICON15 from "../assets/Icon Tikpii/Book_open_alt_light.svg";
import ICON16 from "../assets/Icon Tikpii/icons.svg";
import ICON17 from "../assets/Icon Tikpii/mouse.svg";
import ICON18 from "../assets/Icon Tikpii/crystal-ball.svg";
import ICON19 from "../assets/Icon Tikpii/moped.svg";

export const MockDataCateGories = [
  {
    name: "Thời trang nữ",
    icon: ICON1,
    color: "#FF0099",
  },

  {
    name: "Bách hoá xanh",
    icon: ICON2,
    color: "#00B39D",
  },
  {
    name: "Sức khoẻ",
    icon: ICON3,
    color: "#FF0000",
  },
  {
    name: "Chăm sóc nhà cửa",
    icon: ICON4,
    color: "#E03D3D",
  },

  {
    name: "Sắc đẹp",
    icon: ICON5,
    color: "#CC00FF",
  },
  {
    name: "Túi ví",
    icon: ICON6,
    color: "#FF0099",
  },
  {
    name: "Thời trang nam",
    icon: ICON7,
    color: "#FBA035",
  },

  {
    name: "Bảo hiểm",
    icon: ICON8,
    color: "#D1BE1A",
  },
  {
    name: "Mẹ và bé",
    icon: ICON9,
    color: "#FFB021",
  },
  {
    name: "Thời trang trẻ em",
    icon: ICON10,
    color: "#FF0599",
  },
  {
    name: "Phụ kiện và trang sức",
    icon: ICON11,
    color: "#03007D",
  },

  {
    name: "Thể thao và du lịch",
    icon: ICON12,
    color: "#3C3138",
  },
  {
    name: "Voucher và dịch vụ",
    icon: ICON13,
    color: "#FF0099",
  },
  {
    name: "Đồng hồ",
    icon: ICON14,
    color: "#995300",
  },
  {
    name: "Sách",
    icon: ICON15,
    color: "#FB4C4C",
  },
  {
    name: "Đồ chơi",
    icon: ICON16,
    color: "#F56C4C",
  },
  {
    name: "Thiết bị điện tử",
    icon: ICON17,
    color: "#FF0099",
  },
  {
    name: "Thiết bị gia dụng",
    icon: ICON18,
    color: "#2305DE",
  },
  {
    name: "Ô tô, xe máy điện",
    icon: ICON19,
    color: "#F5AC27",
  },
];
export const mockdataProduct = [
  {
    id: "1",
    name: "Kem dưỡng da",
    description: `Kem dưỡng da được ví như "thức ăn" dành cho da, nó thẩm thấu và nuôi dưỡng da bạn từ sâu bên trong, da bạn sẽ trắng sáng, đều màu, các vết sạm nám, đồi mồi sẽ giảm đi theo thời gian. Chính vì thế nếu chăm chỉ sử dụng đều đặn, da bạn sẽ mịn màng, tăng độ đàn hồi và tươi trẻ hơn.`,
    image: [
      "https://thorakaocaugiay.com/wp-content/uploads/2020/11/Kem-duong-trang-da-sua-de-ngoc-trai-Thorakao-30g_tcg.jpg",
    ],
    price: 100000,
    discount: 20,
    amount: 120,
    Profit: 100000 * 0.06,
    point: 100,
    sold: 120567,
  },
  {
    id: "2",
    name: "Kem dưỡng ẩm",
    description: `Kem dưỡng ẩm chứa các hợp chất hóa học hỗ trợ bên ngoài để giữ nước và duy trì độ ẩm cần thiết cho da, hạn chế tình trạng da khô, bong tróc gây ra bởi lớp ngoài cùng của biểu bì da bị mất nước. Ngoài ra, sản phẩm cũng có tác dụng làm đều màu da, chống oxy hóa và chống lão hóa.`,
    image: [
      "https://bizweb.dktcdn.net/100/202/714/products/17-copy.jpg?v=1519812989540",
    ],
    price: 120000,
    discount: 40,
    amount: 200,
    Profit: 50000 * 0.06,
    point: 1004,
    sold: 120567,
  },
  {
    id: "3",
    name: "Son môi Revolution Lipstick màu Đỏ Đậm Cherry | Princess Beauty",
    description:
      "Son môi là mỹ phẩm có chứa sắc tố, dầu, sáp, chất làm mềm da; có tác dụng tô thoa tạo màu sắc, tạo bề mặt và bảo vệ đôi môi. Nhiều màu sắc và kiểu loại son môi tồn tại. Như với hầu hết nhiều loại mỹ phẩm, son môi khá phổ biến, nhưng không phải độc quyền dành cho phái nữ. Sử dụng son môi đã tồn tại từ thời Trung Cổ.",
    image: [
      "https://princesswhite.net/wp-content/uploads/2019/01/son-m%C3%94i-revolution-lipstck-cam-%C4%90%E1%BA%A5t-2.png",
    ],
    price: 145000,
    discount: 0,
    amount: 80,
    Profit: 145000 * 0.06,
    point: 100,
    sold: 120,
  },
  {
    id: "4",
    name: "Bút Kẻ Mắt Nước Siêu Sắc Mảnh, Không Lem Trôi Maybelline New York Hyper Sharp",
    description:
      "Bút kẻ mắt là một loại mỹ phẩm được sử dụng để định hình đôi mắt. Nó dùng để tô vẽ trên đường viền mắt nhằm tạo ra một loạt hiệu ứng thẩm mỹ.",
    image: [
      "https://product.hstatic.net/1000006063/product/4916_032ea5ce6c1741d480253c59d1b92a69_1024x1024.jpg",
    ],
    price: 16800000,
    discount: 45,
    amount: 120,
    Profit: 100000 * 0.06,
    point: 1200,
    sold: 2567,
  },
  {
    id: "5",
    name: "GƯƠNG TRÒN TREO TƯỜNG O- MIRROR CIRCLE D50 BLACK",
    description:
      "Gương tròn trang trí decor với mặt gương sáng và phần khung được làm bằng chất liệu PU bền đẹp, không thấm nước được thiết kế chắc chắn, không ...",
    image: [
      "https://product.hstatic.net/200000044142/product/guong_tron_treo_tuong_den_0321_5378ab248dfa4fecb9cfa846374ae92b_grande.jpg",
    ],
    price: 100000,
    discount: 20,
    amount: 120,
    Profit: 100000 * 0.06,
    point: 100,
    sold: 120567,
  },
  {
    id: "1",
    name: "Kem dưỡng da",
    description: `Kem dưỡng da được ví như "thức ăn" dành cho da, nó thẩm thấu và nuôi dưỡng da bạn từ sâu bên trong, da bạn sẽ trắng sáng, đều màu, các vết sạm nám, đồi mồi sẽ giảm đi theo thời gian. Chính vì thế nếu chăm chỉ sử dụng đều đặn, da bạn sẽ mịn màng, tăng độ đàn hồi và tươi trẻ hơn.`,
    image: [
      "https://thorakaocaugiay.com/wp-content/uploads/2020/11/Kem-duong-trang-da-sua-de-ngoc-trai-Thorakao-30g_tcg.jpg",
    ],
    price: 100000,
    discount: 20,
    amount: 120,
    Profit: 100000 * 0.06,
    point: 100,
    sold: 120567,
  },
  {
    id: "2",
    name: "Kem dưỡng ẩm",
    description: `Kem dưỡng ẩm chứa các hợp chất hóa học hỗ trợ bên ngoài để giữ nước và duy trì độ ẩm cần thiết cho da, hạn chế tình trạng da khô, bong tróc gây ra bởi lớp ngoài cùng của biểu bì da bị mất nước. Ngoài ra, sản phẩm cũng có tác dụng làm đều màu da, chống oxy hóa và chống lão hóa.`,
    image: [
      "https://bizweb.dktcdn.net/100/202/714/products/17-copy.jpg?v=1519812989540",
    ],
    price: 120000,
    discount: 40,
    amount: 200,
    Profit: 50000 * 0.06,
    point: 1004,
    sold: 120567,
  },
  {
    id: "3",
    name: "Son môi Revolution Lipstick màu Đỏ Đậm Cherry | Princess Beauty",
    description:
      "Son môi là mỹ phẩm có chứa sắc tố, dầu, sáp, chất làm mềm da; có tác dụng tô thoa tạo màu sắc, tạo bề mặt và bảo vệ đôi môi. Nhiều màu sắc và kiểu loại son môi tồn tại. Như với hầu hết nhiều loại mỹ phẩm, son môi khá phổ biến, nhưng không phải độc quyền dành cho phái nữ. Sử dụng son môi đã tồn tại từ thời Trung Cổ.",
    image: [
      "https://princesswhite.net/wp-content/uploads/2019/01/son-m%C3%94i-revolution-lipstck-cam-%C4%90%E1%BA%A5t-2.png",
    ],
    price: 145000,
    discount: 0,
    amount: 80,
    Profit: 145000 * 0.06,
    point: 100,
    sold: 120,
  },
  {
    id: "4",
    name: "Bút Kẻ Mắt Nước Siêu Sắc Mảnh, Không Lem Trôi Maybelline New York Hyper Sharp",
    description:
      "Bút kẻ mắt là một loại mỹ phẩm được sử dụng để định hình đôi mắt. Nó dùng để tô vẽ trên đường viền mắt nhằm tạo ra một loạt hiệu ứng thẩm mỹ.",
    image: [
      "https://product.hstatic.net/1000006063/product/4916_032ea5ce6c1741d480253c59d1b92a69_1024x1024.jpg",
    ],
    price: 16800000,
    discount: 45,
    amount: 120,
    Profit: 100000 * 0.06,
    point: 1200,
    sold: 2567,
  },
  {
    id: "5",
    name: "GƯƠNG TRÒN TREO TƯỜNG O- MIRROR CIRCLE D50 BLACK",
    description:
      "Gương tròn trang trí decor với mặt gương sáng và phần khung được làm bằng chất liệu PU bền đẹp, không thấm nước được thiết kế chắc chắn, không ...",
    image: [
      "https://product.hstatic.net/200000044142/product/guong_tron_treo_tuong_den_0321_5378ab248dfa4fecb9cfa846374ae92b_grande.jpg",
    ],
    price: 100000,
    discount: 20,
    amount: 120,
    Profit: 100000 * 0.06,
    point: 100,
    sold: 120567,
  },
  {
    id: "1",
    name: "Kem dưỡng da",
    description: `Kem dưỡng da được ví như "thức ăn" dành cho da, nó thẩm thấu và nuôi dưỡng da bạn từ sâu bên trong, da bạn sẽ trắng sáng, đều màu, các vết sạm nám, đồi mồi sẽ giảm đi theo thời gian. Chính vì thế nếu chăm chỉ sử dụng đều đặn, da bạn sẽ mịn màng, tăng độ đàn hồi và tươi trẻ hơn.`,
    image: [
      "https://thorakaocaugiay.com/wp-content/uploads/2020/11/Kem-duong-trang-da-sua-de-ngoc-trai-Thorakao-30g_tcg.jpg",
    ],
    price: 100000,
    discount: 20,
    amount: 120,
    Profit: 100000 * 0.06,
    point: 100,
    sold: 120567,
  },
  {
    id: "2",
    name: "Kem dưỡng ẩm",
    description: `Kem dưỡng ẩm chứa các hợp chất hóa học hỗ trợ bên ngoài để giữ nước và duy trì độ ẩm cần thiết cho da, hạn chế tình trạng da khô, bong tróc gây ra bởi lớp ngoài cùng của biểu bì da bị mất nước. Ngoài ra, sản phẩm cũng có tác dụng làm đều màu da, chống oxy hóa và chống lão hóa.`,
    image: [
      "https://bizweb.dktcdn.net/100/202/714/products/17-copy.jpg?v=1519812989540",
    ],
    price: 120000,
    discount: 40,
    amount: 200,
    Profit: 50000 * 0.06,
    point: 1004,
    sold: 120567,
  },
  {
    id: "3",
    name: "Son môi Revolution Lipstick màu Đỏ Đậm Cherry | Princess Beauty",
    description:
      "Son môi là mỹ phẩm có chứa sắc tố, dầu, sáp, chất làm mềm da; có tác dụng tô thoa tạo màu sắc, tạo bề mặt và bảo vệ đôi môi. Nhiều màu sắc và kiểu loại son môi tồn tại. Như với hầu hết nhiều loại mỹ phẩm, son môi khá phổ biến, nhưng không phải độc quyền dành cho phái nữ. Sử dụng son môi đã tồn tại từ thời Trung Cổ.",
    image: [
      "https://princesswhite.net/wp-content/uploads/2019/01/son-m%C3%94i-revolution-lipstck-cam-%C4%90%E1%BA%A5t-2.png",
    ],
    price: 145000,
    discount: 0,
    amount: 80,
    Profit: 145000 * 0.06,
    point: 100,
    sold: 120,
  },
  {
    id: "4",
    name: "Bút Kẻ Mắt Nước Siêu Sắc Mảnh, Không Lem Trôi Maybelline New York Hyper Sharp",
    description:
      "Bút kẻ mắt là một loại mỹ phẩm được sử dụng để định hình đôi mắt. Nó dùng để tô vẽ trên đường viền mắt nhằm tạo ra một loạt hiệu ứng thẩm mỹ.",
    image: [
      "https://product.hstatic.net/1000006063/product/4916_032ea5ce6c1741d480253c59d1b92a69_1024x1024.jpg",
    ],
    price: 16800000,
    discount: 45,
    amount: 120,
    Profit: 100000 * 0.06,
    point: 1200,
    sold: 2567,
  },
  {
    id: "5",
    name: "GƯƠNG TRÒN TREO TƯỜNG O- MIRROR CIRCLE D50 BLACK",
    description:
      "Gương tròn trang trí decor với mặt gương sáng và phần khung được làm bằng chất liệu PU bền đẹp, không thấm nước được thiết kế chắc chắn, không ...",
    image: [
      "https://product.hstatic.net/200000044142/product/guong_tron_treo_tuong_den_0321_5378ab248dfa4fecb9cfa846374ae92b_grande.jpg",
    ],
    price: 100000,
    discount: 20,
    amount: 120,
    Profit: 100000 * 0.06,
    point: 100,
    sold: 120567,
  },
];

export const MockDataIMG = [
  "https://inlachong.com/wp-content/uploads/2020/06/thiet-ke-logo-my-pham-dep-gia-re.jpg",
  "https://trungthanhprint.com/upload/logo-my-pham-11.jpg",
  "https://topprint.vn/wp-content/uploads/2022/08/top-20-mau-logo-my-pham-dep-thoi-thuong-7.jpg",
  "https://inhoangha.com/uploads/logo-starbucks.jpg",
  "https://www.chapi.vn/img/img/article/2021/hermes-paris-logo.png",
  "https://inlachong.com/wp-content/uploads/2020/06/thiet-ke-logo-my-pham-dep-gia-re.jpg",
  "https://trungthanhprint.com/upload/logo-my-pham-11.jpg",
  "https://topprint.vn/wp-content/uploads/2022/08/top-20-mau-logo-my-pham-dep-thoi-thuong-7.jpg",
];

export const MockDataIMGTWO = [
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1733129032/TikpiiMall/Carousel/o5lbdvvc2nntrqr6fiay.png",
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1733129031/TikpiiMall/Carousel/nyokisbe724m5tcskulx.png",
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1732005132/TikpiiMall/Carousel/i9swskr0zszpr52sqepi.png",
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1732005123/TikpiiMall/Carousel/u2emkowncuoxedfgadyx.png",
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1732005123/TikpiiMall/Carousel/et0pajwpzkonmuybdt0y.png",
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1732005123/TikpiiMall/Carousel/kftz9lipuqzhckgy2o7h.png",
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1732005122/TikpiiMall/Carousel/rgxa2nuhsiglat6b3a8x.png",
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1732005122/TikpiiMall/Carousel/j3wd7n8gfnfix2lj7fti.png",
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1732005122/TikpiiMall/Carousel/wggbzgkrmnb1icoglxoj.png",
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1732005121/TikpiiMall/Carousel/xywjthjmozarmfm54oj2.png",
  "https://res.cloudinary.com/dhscgjuis/image/upload/v1726201278/TikpiiMall/Carousel/ouliklh0gfh43oftxatt.png",
];
