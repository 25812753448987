import React from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton } from "@mui/material";
const QuantityButton = ({ onQuant, onRemove, onAdd }) => {
  return (
    <div className="amount">
      <IconButton onClick={onRemove}>
        <RemoveIcon></RemoveIcon>
      </IconButton>

      <p>{onQuant}</p>
      <IconButton onClick={onAdd}>
        <AddIcon></AddIcon>
      </IconButton>
    </div>
  );
};

export default QuantityButton;
