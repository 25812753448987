import React, { useState, useEffect } from "react";
import "./style.css";

// const IMAGES = [prod1, prod2, prod3, prod4];
// const THUMBS = [prod1, prod2, prod3, prod4];

const Gallery = ({ IMAGES = [], THUMBS = [], stateProduct }) => {
  const initialImage = IMAGES[0]?.path;
  const initialThumbs = THUMBS[0]?.path;
  const [currentImage, setCurrentImage] = useState("");
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const handleMouseMove = (e) => {
    const { left, top, width, height } =
      e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100; // Tính vị trí X (%) trong ảnh
    const y = ((e.clientY - top) / height) * 100; // Tính vị trí Y (%) trong ảnh
    setZoomPosition({ x, y });
  };
  const handleClick = (index) => {
    setCurrentImage(IMAGES[index]?.path);
  };

  const removeActivatedClass = (parent) => {
    parent.childNodes.forEach((node) => {
      node.childNodes[0].classList.contains("activated") &&
        node.childNodes[0].classList.remove("activated");
    });
  };

  return (
    <>
      <section className="gallery-holder hide-in-mobile">
        <section style={{ backgroundColor: "white" }} className="gallery">
          <div
            onMouseMove={handleMouseMove}
            onMouseLeave={() => setZoomPosition({ x: 0, y: 0 })}
            className="image-container "
          >
            <img
              src={currentImage || initialImage}
              alt="product-1"
              className="zoom-image"
              // style={{ width: "600px", height: "600px", objectFit: "cover" }}
              style={{
                transformOrigin: `${zoomPosition.x}% ${zoomPosition.y}%`, // Zoom tại vị trí con trỏ
              }}
            />
          </div>

          <div className="thumbnails !grid:im !grid-cols-12 gap-4">
            {THUMBS &&
              THUMBS.map((th, index) => {
                return (
                  <div
                    className="img-holder col-span-3"
                    key={index}
                    onClick={(e) => {
                      handleClick(index);
                      removeActivatedClass(e.currentTarget.parentNode);
                      e.currentTarget.childNodes[0].classList.toggle(
                        "activated"
                      );
                    }}
                  >
                    <div
                      className={`outlay ${index === 0 && "activated"}`}
                    ></div>
                    <img
                      src={th.path}
                      style={{
                        height: "150px",
                        width: "150px",
                        objectFit: "fill",
                      }}
                      alt={`product-${index + 1}`}
                    />
                  </div>
                );
              })}
          </div>
        </section>
      </section>
    </>
  );
};

export default Gallery;
