import * as React from "react";

import LOGO from "../../assets/logo/LOGONEW.svg";
import { useMutation } from "@tanstack/react-query";
import { LOG_OUT } from "../../services/handleAccount/handleAccount";
import { useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded";
import { Avatar, Dropdown, Navbar } from "flowbite-react";
import { Badge } from "@mui/material";

export default function NavbarCustom({
  stateAccount,
  TriggerSetStateAccount,
  stateCart,
}) {
  let nav = useNavigate();

  const MutationLogout = useMutation({
    mutationKey: ["log_out"],
    mutationFn: (body) => {
      return LOG_OUT(body);
    },
  });

  const handleMenuClose = () => {
    MutationLogout.mutate(
      {},
      {
        onSuccess: (data) => {
          localStorage.clear();
          TriggerSetStateAccount();
        },
        onError: (err) => {
          // localStorage.clear();
        },
      }
    );
  };

  return (
    <Navbar fluid rounded>
      <Navbar.Brand href="/">
        <img
          src={LOGO}
          width={200}
          style={{ height: "auto" }}
          alt="LOGO TIKPII"
        />
      </Navbar.Brand>
      <div className="flex md:order-2">
        {stateAccount?._id ? (
          <>
            {" "}
            <Dropdown
              arrowIcon={false}
              inline
              label={
                <Avatar
                  alt="User settings"
                  src={AccountBoxRoundedIcon}
                  rounded
                />
              }
            >
              <Dropdown.Header>
                <span className="block truncate text-sm font-medium">
                  {stateAccount?.name}
                </span>
              </Dropdown.Header>
              <Dropdown.Item
                onClick={() => {
                  nav("/cart");
                }}
              >
                Giỏ hàng{" "}
                <Badge
                  sx={{ marginLeft: "1rem" }}
                  badgeContent={stateCart[0]?.purchase_items?.length || 0}
                  color="error"
                >
                  <ShoppingCartIcon fontSize="small" color="action" />
                </Badge>
              </Dropdown.Item>
              <Dropdown.Item>
                Đơn hàng{" "}
                <Badge
                  sx={{ marginLeft: "1rem" }}
                  badgeContent={0}
                  color="error"
                >
                  <InventoryRoundedIcon fontSize="small" color="action" />
                </Badge>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleMenuClose}>Đăng xuất</Dropdown.Item>
            </Dropdown>
            <Navbar.Toggle />
          </>
        ) : (
          <span
            onClick={() => {
              nav("/login");
            }}
            className="text-sm text-black font-bold"
          >
            Đăng nhập
          </span>
        )}
      </div>
      <Navbar.Collapse>
        <Navbar.Link
          onClick={() => {
            nav("/product");
          }}
          className="text-sm"
        >
          Sản phẩm
        </Navbar.Link>
        <Navbar.Link href="#" className="text-sm">
          Bài viết
        </Navbar.Link>
        <Navbar.Link href="#" className="text-sm">
          Liên hệ
        </Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  );
}
