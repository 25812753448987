import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { ButtonSignIn, FormBase } from "./Component/Component";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { Handle_Check_User } from "../../services/handleAccount/handleAccount";
import { Theme } from "../../theme";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
export const CheckF = ({
  nav,
  sLogin,
  isLoading,
  DataError,
  handleClickShowPassword,
  showPassword,
  setFormLogin,
  FormLogin,
  setStateCheck,
}) => {
  const [stateError, setStateError] = useState({});
  const [stateLoading, setStateLoading] = useState(false);

  const MutationCheck = useMutation({
    mutationKey: ["HANDLE_CHECK"],
    mutationFn: (body) => {
      return Handle_Check_User(body);
    },
  });
  const validate = () => {
    const newErrors = {};

    if (!FormLogin.phone) {
      newErrors.phone = "Vui lòng không bỏ trống số điện thoại !!";
    }

    setStateError(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const sCheck = () => {
    setStateLoading(true);

    if (validate()) {
      try {
        MutationCheck.mutate(
          { phone: FormLogin?.phone },
          {
            onSuccess: (data) => {
              if (data?.data?.data) {
                if (data?.data?.data?.type == 1) {
                  setStateCheck("login");
                }
                if (data?.data?.data?.type == 2) {
                  setStateCheck("register");
                }
                if (data?.data?.data?.type == 3) {
                  const newErrors = {};

                  newErrors.phone =
                    "ID tài khoản này không có trên hệ thống !!";

                  setStateError(newErrors);
                }
              }
              // console.log("data ", JSON.stringify(data, null, 2));
              setStateLoading(false);
            },
            onError: (err) => {
              console.log("err ", err);
              setStateLoading(false);
            },
          }
        );
      } catch (error) {
        setStateLoading(false);
      }
    } else {
      setStateLoading(false);
    }
  };
  return (
    <>
      <Box>
        {" "}
        <Box
          p={3}
          sx={{
            backgroundColor: Theme.designColors.PrimaryBrand3,
            borderRadius: "20px",
            borderColor: Theme.designColors.PrimaryBrand4,
            borderWidth: "1px",
          }}
        >
          <Box
            mt={4}
            sx={{
              paddingLeft: "2rem",
              paddingRight: "2rem",
              width: "100%",
              height: "62%",
            }}
          >
            <Box mb={2} display={"flex"}>
              <Typography
                color={"#393E40"}
                fontWeight={"700"}
                fontSize={"25px"}
                margin={"auto"}
                style={{ cursor: "pointer" }}
              >
                Đăng nhập tài khoản
              </Typography>
            </Box>
            <form>
              <Box class="form-group">
                <TextField
                  variant="outlined"
                  placeholder="Số điện thoại"
                  name="phone"
                  value={FormLogin?.phone}
                  onChange={(e) => {
                    setFormLogin({
                      ...FormLogin,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        sx={{
                          height: "40px",
                          borderRight: "solid 1px ",
                          paddingRight: "10px",
                        }}
                        position="start"
                      >
                        <LocalPhoneOutlinedIcon />
                      </InputAdornment>
                    ),
                    style: {
                      height: "50px",
                    },
                  }}
                  fullWidth
                  sx={{
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
                    backgroundColor: "white",
                    borderRadius: "20px",
                    height: "50px",
                    cursor: "pointer",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      "& fieldset": {
                        border: "none", // Xóa viền ngoài
                      },
                      "&:hover fieldset": {
                        border: "none", // Xóa viền khi hover
                      },
                      "&.Mui-focused fieldset": {
                        border: "none", // Xóa viền khi có focus
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "12px",

                      height: "40px", // Đảm bảo nội dung và input có chiều cao đồng nhất
                    },
                    "& .MuiInputBase-input": {
                      padding: "10px 12px", // Điều chỉnh padding bên trong để có kích thước phù hợp
                    },
                  }}
                />
              </Box>
            </form>

            {stateError && stateError?.phone ? (
              <Alert style={{ marginTop: "1rem" }} severity="error">
                {stateError?.phone}
              </Alert>
            ) : (
              ""
            )}

            <Box mt={2} display={"flex"} justifyContent={"end"}>
              <Typography fontWeight={"500"} color={"#393E40"}>
                Quên mật khẩu ?
              </Typography>
            </Box>
            <Box mt={2} display={"flex"} justifyContent={"end"}>
              {stateLoading ? (
                <ButtonSignIn disabled={stateLoading}>
                  {" "}
                  <CircularProgress
                    sx={{ fontSize: "10px" }}
                  ></CircularProgress>
                </ButtonSignIn>
              ) : (
                <ButtonSignIn
                  onClick={() => {
                    sCheck();
                  }}
                >
                  Đăng nhập
                </ButtonSignIn>
              )}
            </Box>
            <Box mt={2} display={"grid"} justifyContent={"center"}>
              <Typography
                variant={"subtitle2"}
                color={"#5C6366"}
                textAlign={"center"}
              >
                hoặc
              </Typography>

              <Typography
                mt={1}
                fontSize={"14px"}
                fontWeight={"600"}
                color={"#1B3FE4"}
              >
                Đăng nhập bằng số điện thoại
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
