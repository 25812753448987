import Navbar from "../../component/Navbar/navbar";

import Footer from "../../component/Footer/footer";
import * as React from "react";
import { useParams, useLocation } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { Images as images } from "./mockdata";
import "./App.css";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Link,
  Pagination,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import Gallery from "./Components/Gallery";
import Description from "./Components/Description";
import MobileGallery from "./Components/MobileGallery";
import { mockdataProduct } from "../../data/data";
import {
  GET_ALL_PRODUCT_OF_CATEGORY,
  GET_PRODUCT,
} from "../../services/handleProduct/handleProduct";
import { useQuery } from "@tanstack/react-query";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { Theme } from "../../theme";
import { BreadcrumbsCustom } from "../../component/component";
import { useState, useEffect } from "react";
import { TitleProduct } from "../../component/Styles/Title";
const ProductDetails = ({ sendDataToBase, TriggerRefreshCart }) => {
  const [stateProduct, setStateproduct] = useState({});
  const { productid } = useParams();
  const { state } = useLocation();

  const [quant, setQuant] = useState(0);
  const [orderedQuant, setOrderedQuant] = useState(0);

  const [statePage, setStatePage] = useState(1);
  const [state_Total_Page, setState_Total_Page] = useState(1);
  const [stateLimit, setStateLimit] = useState(5);
  const [stateProductLQ, setStateProductLQ] = useState([]);
  const [state_Total_Product, setState_Total_Product] = useState(0);
  let nav = useNavigate();
  let navCustomBread = [
    {
      nav: "/",
      name: "Trang chủ",
    },
    {
      nav: "",
      name: "Chi tiết sản phẩm",
    },
    {
      nav: "",
      name: stateProduct?.name,
    },
  ];
  const DataGetProduct = useQuery({
    queryKey: ["GetProduct"],
    queryFn: async () => {
      const result = await GET_PRODUCT({
        productID: productid,
      });
      if (result?.data?.data) {
        setStateproduct(result?.data?.data);
      }
      return result; // Ensure the result is returned
    },
  });

  const DataAllProduct = useQuery({
    queryKey: ["ALL_PRODUCT_OF_CATEGORY", statePage],
    queryFn: async () => {
      const result = await GET_ALL_PRODUCT_OF_CATEGORY({
        id: state || stateProduct?.category?._id,
        page: statePage,
        limit: stateLimit,
      });
      if (result?.data?.data?.products) {
        setStateProductLQ(result?.data?.data?.products);
        setState_Total_Product(result?.data?.data?.total_product);
        setState_Total_Page(result?.data?.data?.total_page);
      }
      return result;
    },
  });

  const addQuant = () => {
    setQuant(quant + 1);
  };

  const removeQuant = () => {
    setQuant(quant - 1);
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Cuộn lên đầu trang mỗi khi id thay đổi
    DataGetProduct?.refetch();
    DataAllProduct?.refetch();
  }, [productid, state]);
  return (
    <>
      <BreadcrumbsCustom ListBreed={navCustomBread}></BreadcrumbsCustom>
      <Box>
        <Container
          style={{
            backgroundColor: "white",
            padding: "0px",
            maxWidth: "1500px",
          }}
          component="section"
          maxWidth={"lg"}
        >
          {DataGetProduct?.isFetching ? (
            "Đang tải dữ liệu"
          ) : (
            <>
              {/* //DESKTOP */}
              <Box>
                {stateProduct?.images ? (
                  <section
                    style={{
                      backgroundColor: "white",
                      // gap: "100px",
                      margin: "60px auto",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    className="core row"
                  >
                    <Box
                      className="col-md-5"
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <Gallery
                        IMAGES={stateProduct?.images || []}
                        THUMBS={stateProduct?.images || []}
                      />
                      <MobileGallery
                        IMAGES={stateProduct?.images || []}
                        THUMBS={stateProduct?.images || []}
                      />
                    </Box>
                    <Box className="col-md-5">
                      <Description
                        onQuant={quant}
                        onAdd={addQuant}
                        onRemove={removeQuant}
                        onSetOrderedQuant={setOrderedQuant}
                        stateProduct={stateProduct}
                        TriggerRefreshCart={TriggerRefreshCart}
                      />
                    </Box>
                  </section>
                ) : (
                  "Không tìm thấy sản phẩm"
                )}
              </Box>
            </>
          )}
        </Container>
        <Box className="container flex p-0 mt-20 gap-2 flex-col items-start">
          <span className="text-black text-2xl font-semibold">
            Sản phẩm có liên quan
          </span>
          <div
            style={{ width: "100%" }}
            className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 gap-1"
          >
            {DataAllProduct?.isFetching ? (
              <>
                {" "}
                {[...Array(5)].map((_, index) => (
                  <div>
                    <Skeleton variant="rounded" height={250} />
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  </div>
                ))}
              </>
            ) : (
              <>
                {" "}
                {stateProductLQ &&
                  stateProductLQ.map((item, index) => (
                    <Card
                      onClick={() => {
                        nav(`/pDetails/${item?._id}`, {
                          state: item?.category?._id,
                        });
                      }}
                      sx={{
                        borderRadius: "10px",
                        position: "relative",
                        // display: "inline-block",
                      }}
                    >
                      <CardActionArea
                        sx={{ minHeight: "350px", height: "270px" }}
                      >
                        <CardMedia
                          component="img"
                          sx={{
                            padding: "0px 10px",
                            minHeight: "125px",
                            height: "125px",
                          }}
                          image={item?.images[0]?.path}
                          alt="green iguana"
                        />
                        <CardContent>
                          <Typography
                            gutterBottom
                            fontSize={Theme.designColors.SUBTITLESIZE}
                            component="div"
                          >
                            <TitleProduct> {item?.name}</TitleProduct>
                          </Typography>
                          <Box display={"flex"} gap={1} alignItems={"center"}>
                            <Typography variant="body2" color="text.secondary">
                              Giá:{" "}
                            </Typography>
                            <Typography
                              fontSize={Theme.designColors.SUBTITLESIZE2}
                              fontWeight={600}
                              color={Theme.designColors.PrimaryBrand1}
                            >
                              {" "}
                              {parseInt(item?.price_retail).toLocaleString(
                                "en-US"
                              )}{" "}
                              VND
                            </Typography>
                          </Box>
                          <Box display={"flex"} gap={1} alignItems={"center"}>
                            <Typography variant="body2" color="text.secondary">
                              Tồn kho:{" "}
                            </Typography>
                            <Typography
                              fontSize={Theme.designColors.SUBTITLESIZE2}
                              variant="body2"
                              color="text.secondary"
                            >
                              {" "}
                              {item?.store_company?.stock}
                            </Typography>
                          </Box>
                        </CardContent>
                      </CardActionArea>
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          backgroundColor: "rgba(204, 51, 102, 0.5)",

                          color: "white",
                          padding: "10px",
                          borderRadius: "0 0 10px 0",
                        }}
                      >
                        HOT
                      </div>
                    </Card>
                  ))}
              </>
            )}
          </div>
          <Box width={"100%"} mt={3}>
            <Stack spacing={2}>
              <span className="text-sm text-black">Trang: {statePage}</span>
              <Pagination
                variant="outlined"
                shape="rounded"
                count={state_Total_Page}
                page={statePage}
                onChange={(event, value) => {
                  setStatePage(value);
                }}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ProductDetails;
