// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cssforButtonItem {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
    width: auto;
    height: 40px;
    font-weight: 500;
    font-size: 0.8rem;
}
@keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .element {
    animation: bounce 2s infinite ease-in-out;
  }

  .productEle {
    border: 2px solid transparent;
    transition: border-color 0.5s ease;
  }
  
  .productEle:hover {
    border-color: orange;
  }
`, "",{"version":3,"sources":["webpack://./src/pages/Product/App.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;IACrB,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI;MACE,wBAAwB;IAC1B;IACA;MACE,4BAA4B;IAC9B;IACA;MACE,wBAAwB;IAC1B;EACF;;EAEA;IACE,yCAAyC;EAC3C;;EAEA;IACE,6BAA6B;IAC7B,kCAAkC;EACpC;;EAEA;IACE,oBAAoB;EACtB","sourcesContent":[".cssforButtonItem {\r\n  white-space: nowrap;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n    width: auto;\r\n    height: 40px;\r\n    font-weight: 500;\r\n    font-size: 0.8rem;\r\n}\r\n@keyframes bounce {\r\n    0% {\r\n      transform: translateY(0);\r\n    }\r\n    50% {\r\n      transform: translateY(-10px);\r\n    }\r\n    100% {\r\n      transform: translateY(0);\r\n    }\r\n  }\r\n  \r\n  .element {\r\n    animation: bounce 2s infinite ease-in-out;\r\n  }\r\n\r\n  .productEle {\r\n    border: 2px solid transparent;\r\n    transition: border-color 0.5s ease;\r\n  }\r\n  \r\n  .productEle:hover {\r\n    border-color: orange;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
