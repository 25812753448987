import "./App.css";
import {
  HashRouter,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import Auth from "./pages/Auth/Auth";

import Base from "./component/Base/Base";

import { RoutePublic } from "./RoutePublic";
function App() {
  return (
    <Routes>
      {RoutePublic.map((item, index) => (
        <Route path={item.path} element={<Base>{item.element}</Base>}></Route>
      ))}

      <Route path="/login" element={<Auth check="login"></Auth>}></Route>
    </Routes>
  );
}

export default App;
