import axios from "axios";
import { URL_BACKEND } from "../../contanst/contanst";
import Cookies from "js-cookie";
import axiosRetry from "axios-retry";

// Tạo instance Axios

const api = axios.create({
  baseURL: `${URL_BACKEND}`, // Đặt base URL cho tất cả các yêu cầu
  withCredentials: true,
  timeout: 15000,
});

// Cấu hình axios-retry để tự động retry các yêu cầu bị lỗi
axiosRetry(api, {
  retries: 3, // Số lần retry tối đa
  retryCondition: (error) => {
    return error.code === "ECONNRESET" || error.code === "ETIMEDOUT"; // Điều kiện để retry
  },
  retryDelay: (retryCount) => {
    return retryCount * 1000; // Tăng thời gian chờ giữa các lần retry
  },
});

// Interceptor để thêm authorization header
api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("mToken"); // Lấy access token từ localStorage
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`; // Gán token vào header
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor để xử lý các phản hồi có lỗi
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Gọi API làm mới token

      try {
        const response = await axios.post(
          `${URL_BACKEND}/customers/auth/refresh-token`,
          {},
          {
            withCredentials: true, // Đảm bảo cookie được gửi cùng yêu cầu
          }
        );

        const newAccessToken = response.data; // Lấy token mới
        localStorage.setItem("mToken", newAccessToken.data.access_token); // Lưu lại token mới
        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${newAccessToken}`; // Cập nhật lại headers

        // Thực hiện lại yêu cầu ban đầu
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return api(originalRequest);
      } catch (err) {
        console.error("Không thể làm mới token:", err.message);
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
