import * as React from "react";

import LOGO from "../../assets/logo/LOGONEW.svg";
import { useMutation } from "@tanstack/react-query";
import { LOG_OUT } from "../../services/handleAccount/handleAccount";
import { useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded";
import { Avatar, Dropdown, Navbar } from "flowbite-react";
import { Badge } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
export default function NavbarCustom({
  stateAccount,
  TriggerSetStateAccount,
  stateCart,
}) {
  let nav = useNavigate();

  const MutationLogout = useMutation({
    mutationKey: ["log_out"],
    mutationFn: (body) => {
      return LOG_OUT(body);
    },
  });

  const handleMenuClose = () => {
    MutationLogout.mutate(
      {},
      {
        onSuccess: (data) => {
          localStorage.clear();
          TriggerSetStateAccount();
        },
        onError: (err) => {
          // localStorage.clear();
        },
      }
    );
  };

  return (
    <>
      <Navbar className="container" rounded>
        <Navbar.Brand href="/">
          <img
            src={LOGO}
            width={200}
            style={{ height: "auto" }}
            alt="LOGO TIKPII"
          />
        </Navbar.Brand>
        <div className="flex md:order-2">
          {stateAccount?._id ? (
            <>
              {" "}
              <Dropdown
                arrowIcon={false}
                inline
                style={{ marginRight: "1rem" }}
                label={
                  <Avatar
                    alt="User settings"
                    src={AccountBoxRoundedIcon}
                    rounded
                  />
                }
              >
                <Dropdown.Header>
                  <span className="block truncate text-sm font-medium">
                    {stateAccount?.name}
                  </span>
                </Dropdown.Header>
                <Dropdown.Item
                  onClick={() => {
                    nav("/cart");
                  }}
                >
                  Giỏ hàng{" "}
                  <Badge
                    sx={{ marginLeft: "1rem" }}
                    badgeContent={stateCart[0]?.purchase_items?.length || 0}
                    color="error"
                  >
                    <ShoppingCartIcon fontSize="small" color="action" />
                  </Badge>
                </Dropdown.Item>
                <Dropdown.Item>
                  Đơn hàng{" "}
                  <Badge
                    sx={{ marginLeft: "1rem" }}
                    badgeContent={0}
                    color="error"
                  >
                    <InventoryRoundedIcon fontSize="small" color="action" />
                  </Badge>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleMenuClose}>
                  Đăng xuất
                </Dropdown.Item>
              </Dropdown>
              <Navbar.Toggle />
            </>
          ) : (
            <>
              <div className="flex justify-between items-center gap-2">
                <span
                  onClick={() => {
                    nav("/login");
                  }}
                  className="text-sm text-black font-bold hover:cursor-pointer"
                >
                  Đăng nhập
                </span>
                <div className="hide-in-desktop">
                  <Dropdown
                    arrowIcon={true}
                    inline
                    style={{ marginRight: "1rem", padding: "0px" }}
                    label={<ListIcon></ListIcon>}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        nav("/product");
                      }}
                    >
                      {" "}
                      Sản phẩm
                    </Dropdown.Item>
                    <Dropdown.Item>Bài viết </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        nav("/contact");
                      }}
                    >
                      Liên hệt{" "}
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              </div>
            </>
          )}
        </div>
        <Navbar.Collapse>
          <Navbar.Link
            onClick={() => {
              nav("/product");
            }}
            style={{ cursor: "pointer", textDecoration: "none" }}
            className="text-sm"
          >
            Sản phẩm
          </Navbar.Link>
          <Navbar.Link
            href="#"
            style={{ cursor: "pointer", textDecoration: "none" }}
            className="text-sm"
          >
            Bài viết
          </Navbar.Link>
          <Navbar.Link
            onClick={() => {
              nav("/contact");
            }}
            style={{ cursor: "pointer", textDecoration: "none" }}
            className="text-sm"
          >
            Liên hệ
          </Navbar.Link>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
