import styled from "styled-components";

export const TitleProduct = styled.div`
  margin: 0;

  font-weight: 700;
  color: #595959;
  color: rgb(57 62 64 / 1);
  font-size: 1rem;
  line-height: 2.334;
  letter-spacing: 0em;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
