import {
  Avatar,
  Box,
  ButtonGroup,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Pagination,
  Rating,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { TitleProduct } from "../../component/Styles/Title";
import { Theme } from "../../theme";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import styled from "styled-components";
import { GET_ALL_PRODUCT_OF_PROVIEDR } from "../../services/handleProduct/handleProduct";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { BreadcrumbsCustom } from "../../component/component";
import { GET_PROVIDER_BY_ID } from "../../services/handleProvider/handleProvider";
import XACTHUCPDP from "../../assets/image/pdp/xacthucncc.png";
import { GET_ALL_PRODUCT_OF_CATEGORY } from "../../services/handleProduct/handleProduct";
const ProductOfCategory = () => {
  let nav = useNavigate();
  const { state } = useLocation();

  const { categoryid } = useParams();
  const [statePage, setStatePage] = useState(1);
  const [state_Total_Page, setState_Total_Page] = useState(1);
  const [stateLimit, setStateLimit] = useState(15);
  const [stateProduct, setStateProduct] = useState([]);
  const [state_Total_Product, setState_Total_Product] = useState([]);
  const [stateProvider, setStateProvider] = useState({});
  let CustomBread = [
    {
      nav: "/",
      name: "Trang chủ",
    },
    {
      nav: "",
      name: "Mục lục",
    },
    {
      nav: "",
      name: state?.name,
    },
  ];
  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "#ff6d75",
    },
    "& .MuiRating-iconHover": {
      color: "#ff3d47",
    },
  });

  const DataAllProduct = useQuery({
    queryKey: ["ALL_PRODUCT_OF_CATEGORY", statePage],
    queryFn: async () => {
      const result = await GET_ALL_PRODUCT_OF_CATEGORY({
        id: categoryid,
        page: statePage,
        limit: stateLimit,
      });
      if (result?.data?.data?.products) {
        setStateProduct(result?.data?.data?.products);
        setState_Total_Product(result?.data?.data?.total_product);
        setState_Total_Page(result?.data?.data?.total_page);
      }
      return result;
    },
  });
  const DataGetProvider = useQuery({
    queryKey: ["GET_PROVIDER"],
    queryFn: async () => {
      const result = await GET_PROVIDER_BY_ID(categoryid);
      if (result?.data?.data) {
        setStateProvider(result?.data?.data);
      }
      return result;
    },
  });
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        className="container"
      >
        <Box mt={3}>
          <BreadcrumbsCustom ListBreed={CustomBread}></BreadcrumbsCustom>
        </Box>

        <Box className="row">
          <Box display={"flex"} gap={1}>
            <Avatar
              sx={{ width: 70, height: 70, borderWidth: "1px" }}
              display={"flex"}
              src={state?.image}
            ></Avatar>
            <Box display={"flex"} flexDirection={"column"}>
              <Typography
                sx={{ fontSize: Theme.designColors.TITLESIZE, fontWeight: 600 }}
              >
                {" "}
                {stateProvider?.name}
              </Typography>

              <Typography display={"flex"}>
                Số lượng sản phẩm : {state_Total_Product}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Typography
          mt={3}
          fontSize={Theme.designColors.TITLESIZE}
          fontWeight={600}
        >
          Sản phẩm
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            gap: 1,
            width: "100%",
          }}
        >
          {DataAllProduct?.isFetching ? (
            <>
              {" "}
              <Box>
                <Skeleton variant="rectangular" width={210} height={310} />
                <Skeleton width="100%" />
                <Skeleton width="100%" />
              </Box>
              <Box>
                <Skeleton variant="rectangular" width={210} height={310} />
                <Skeleton width="100%" />
                <Skeleton width="100%" />
              </Box>
              <Box>
                <Skeleton variant="rectangular" width={210} height={310} />
                <Skeleton width="100%" />
                <Skeleton width="100%" />
              </Box>
            </>
          ) : (
            <>
              {" "}
              {stateProduct && stateProduct.length > 0 ? (
                <>
                  {" "}
                  {stateProduct &&
                    stateProduct.map((item, index) => (
                      <>
                        <Card
                          sx={{
                            borderRadius: "0px",
                            boxShadow: "none",
                            position: "relative",
                            borderWidth: "1px",
                            width: "238px",
                            padding: "0px",
                            // display: "inline-block",
                          }}
                        >
                          <CardActionArea
                            sx={{ minHeight: "370px", height: "370px" }}
                          >
                            <CardMedia
                              component="img"
                              sx={{
                                padding: "0px 10px",
                                minHeight: "125px",
                                height: "125px",
                              }}
                              onClick={() => {
                                nav(`/pDetails/${item?._id}`);
                              }}
                              // minHeight={140}

                              image={item?.images[0]?.path}
                              alt="green iguana"
                            />
                            <CardContent>
                              <Typography
                                gutterBottom
                                fontSize={Theme.designColors.SUBTITLESIZE}
                                component="div"
                              >
                                <TitleProduct> {item?.name}</TitleProduct>
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Giá:{" "}
                                </Typography>
                                <Typography
                                  fontSize={Theme.designColors.SUBTITLESIZE2}
                                  fontWeight={600}
                                  color={Theme.designColors.PrimaryBrand1}
                                >
                                  {" "}
                                  {parseInt(item?.price_retail).toLocaleString(
                                    "en-US"
                                  )}{" "}
                                  VND
                                </Typography>
                              </Box>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Tồn kho:{" "}
                                </Typography>
                                <Typography
                                  fontSize={Theme.designColors.SUBTITLESIZE2}
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {" "}
                                  {item?.store_company?.stock}
                                </Typography>
                              </Box>

                              <Box
                                className="row"
                                alignItems={"center"}
                                justifyContent={"space-between"}
                              >
                                <StyledRating
                                  className="col-md-6"
                                  name="customized-color"
                                  defaultValue={4}
                                  getLabelText={(value) =>
                                    `${value} Heart${value !== 1 ? "s" : ""}`
                                  }
                                  precision={0.5}
                                  icon={<FavoriteIcon fontSize="inherit" />}
                                  emptyIcon={
                                    <FavoriteBorderIcon fontSize="inherit" />
                                  }
                                />
                                <Typography
                                  className="col-md-6"
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Đã bán: {item?.sold}
                                </Typography>
                              </Box>
                              <Box className="row">
                                <Box display={"flex"} className="col-md-12">
                                  <LocationOnOutlinedIcon
                                    fontSize="small"
                                    sx={{ color: "gray" }}
                                  ></LocationOnOutlinedIcon>{" "}
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {item?.store_company?.name}
                                  </Typography>
                                </Box>
                              </Box>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </>
                    ))}
                </>
              ) : (
                <Typography>Hiện tại chưa có sản phẩm nào</Typography>
              )}
            </>
          )}
        </Box>

        <Box mt={3}>
          <Stack spacing={2}>
            <Typography>Trang: {statePage}</Typography>
            <Pagination
              variant="outlined"
              shape="rounded"
              count={state_Total_Page}
              page={statePage}
              onChange={(event, value) => {
                setStatePage(value);
              }}
            />
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default ProductOfCategory;
