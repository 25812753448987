import "./App.css";
import {
  GET_ALL_PRODUCT,
  GET_ALL_PRODUCT_OF_CATEGORY,
} from "../../services/handleProduct/handleProduct";

import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Pagination,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { DrawerCategory, ListAccordion } from "./Components/Components";
import { BreadcrumbsCustom } from "../../component/component";
import { Theme } from "../../theme";
import { TitleProduct } from "../../component/Styles/Title";
import { useNavigate } from "react-router-dom";
const Product = ({
  stateCategory,
  stateProvider,
  isloadingCategory,
  isloadingProvider,
}) => {
  let nav = useNavigate();
  const [stateProduct, setStateProduct] = useState([]);
  const [statePage, setStatePage] = useState(1);
  const [stateLimit, setStateLimit] = useState(15);
  const [stateCount, setStateCount] = useState(0);
  const [stateTotalProduct, setStateTotalProduct] = useState(0);

  const [stateIDCategory, setStateIDCategory] = useState("");
  const handleChange = (event, value) => {
    setStatePage(value);
  };
  let navCustomBread = [
    {
      nav: "/",
      name: "Trang chủ",
    },
    {
      nav: "",
      name: "Sản phẩm",
    },
  ];
  const DataAllProduct = useQuery({
    queryKey: ["GetAllProduct", statePage],
    queryFn: async () => {
      const result = await GET_ALL_PRODUCT({
        page: statePage,
        limit: stateLimit,
      });
      if (result?.data?.data) {
        setStateCount(result?.data?.data?.total_page);
        setStateTotalProduct(result?.data?.data?.total_product);
        setStateProduct(result?.data?.data?.products);
      }
      return result;
    },
  });
  const DataAllProductOfCategory = useQuery({
    queryKey: ["ALL_PRODUCT_OF_CATEGORY", statePage, stateIDCategory],
    queryFn: async () => {
      const result = await GET_ALL_PRODUCT_OF_CATEGORY({
        id: stateIDCategory,
        page: statePage,
        limit: stateLimit,
      });
      if (result?.data?.data?.products) {
        setStateProduct(result?.data?.data?.products);
        setStateCount(result?.data?.data?.total_page);
        setStateTotalProduct(result?.data?.data?.total_product);
      }
      return result;
    },
  });
  return (
    <>
      <BreadcrumbsCustom ListBreed={navCustomBread}></BreadcrumbsCustom>
      <Box className="container relative p-0">
        <DrawerCategory
          setStateIDCategory={setStateIDCategory}
          setStatePage={setStatePage}
          stateCategory={stateCategory}
        ></DrawerCategory>
        <Box className=" gap-4 grid grid-cols-9  md:grid-cols-12 w-full">
          <Box className="col-span-3 hidden md:block ">
            <ListAccordion
              stateCategory={stateCategory}
              setStateIDCategory={setStateIDCategory}
              setStatePage={setStatePage}
            ></ListAccordion>
          </Box>
          <Box className="col-span-9">
            <Box className="flex container p-0 mt-5 gap-4 flex-col">
              <span className="text-black text-2xl font-semibold">
                Sản phẩm ({stateTotalProduct})
              </span>
              <div
                style={{ width: "100%" }}
                className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-1"
              >
                {DataAllProduct?.isFetching ? (
                  <>
                    {" "}
                    {[...Array(5)].map((_, index) => (
                      <div>
                        <Skeleton variant="rounded" height={250} />
                        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {" "}
                    {stateProduct &&
                      stateProduct.map((item, index) => (
                        <Card
                          sx={{
                            borderRadius: "10px",
                            position: "relative",
                            boxShadow: "none",
                            // display: "inline-block",
                          }}
                          onClick={() => {
                            nav(`/pDetails/${item?._id}`, {
                              state: item?.category?._id,
                            });
                          }}
                        >
                          <CardActionArea
                            sx={{ minHeight: "350px", height: "270px" }}
                          >
                            <CardMedia
                              component="img"
                              sx={{
                                padding: "0px 10px",
                                minHeight: "125px",
                                height: "125px",
                              }}
                              image={item?.images[0]?.path}
                              alt="green iguana"
                            />
                            <CardContent>
                              <Typography
                                gutterBottom
                                fontSize={Theme.designColors.SUBTITLESIZE}
                                component="div"
                              >
                                <TitleProduct> {item?.name}</TitleProduct>
                              </Typography>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Giá:{" "}
                                </Typography>
                                <Typography
                                  fontSize={Theme.designColors.SUBTITLESIZE2}
                                  fontWeight={600}
                                  color={Theme.designColors.PrimaryBrand1}
                                >
                                  {" "}
                                  {parseInt(
                                    item?.price_for_customer || 0
                                  ).toLocaleString("en-US")}{" "}
                                  VND
                                </Typography>
                              </Box>
                              <Box
                                display={"flex"}
                                gap={1}
                                alignItems={"center"}
                              >
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Tồn kho:{" "}
                                </Typography>
                                <Typography
                                  fontSize={Theme.designColors.SUBTITLESIZE2}
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {" "}
                                  {item?.store_company?.stock}
                                </Typography>
                              </Box>
                            </CardContent>
                          </CardActionArea>
                          {parseInt(item?.sales) > 0 ? (
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                backgroundColor: "#FDFD96",

                                color: "#333333",
                                padding: "10px",
                                fontSize: "14px",
                                fontWeight: 600,
                                borderRadius: "0 0 10px 0",
                              }}
                            >
                              GIẢM GIÁ: {parseInt(item?.sales)}%
                            </div>
                          ) : (
                            ""
                          )}
                        </Card>
                      ))}
                  </>
                )}
              </div>
              <Box width={"100%"} mt={3}>
                <Stack spacing={2}>
                  <span className="text-sm text-black">Trang: {statePage}</span>
                  <Pagination
                    variant="outlined"
                    shape="rounded"
                    count={stateCount}
                    page={statePage}
                    onChange={handleChange}
                  />
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Product;
