// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-item.active {
  color: #1a237e;
  font-weight: 700;
}
.zoom-image {
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  transition: transform 0.3s ease; 
}

.zoom-image:hover {
  transform: scale(1.5); 
  cursor: pointer; 
}
.image-container {
  position: relative;
  overflow: hidden; 
  object-fit: cover;
  width: 500px; 
  
  height: 500px; 

}`, "",{"version":3,"sources":["webpack://./src/pages/productDetails/Components/style.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;EACrB,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;;EAEZ,aAAa;;AAEf","sourcesContent":[".menu-item.active {\r\n  color: #1a237e;\r\n  font-weight: 700;\r\n}\r\n.zoom-image {\r\n  width: 100%; \r\n  height: 100%; \r\n  object-fit: cover; \r\n  transition: transform 0.3s ease; \r\n}\r\n\r\n.zoom-image:hover {\r\n  transform: scale(1.5); \r\n  cursor: pointer; \r\n}\r\n.image-container {\r\n  position: relative;\r\n  overflow: hidden; \r\n  object-fit: cover;\r\n  width: 500px; \r\n  \r\n  height: 500px; \r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
