import Axios from "axios";
import api from "../common/apis";
import NProgress from "nprogress";
import { URL_BACKEND } from "../../contanst/contanst";

const apiLogout = Axios.create({
  baseURL: `${URL_BACKEND}`, // Đặt base URL cho tất cả các yêu cầu
  withCredentials: true,
  timeout: 15000,
});

export const HandleLogin = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(
      `/customers/auth/login`,
      {
        phone: req.phone,
        password: req.password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
export const Handle_Check_User = async (req) => {
  try {
    NProgress.start();
    const res = await api.post(
      `/customers/auth/check-account-customer`,
      {
        phone: req.phone,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};

export const HandleRegister = async (req) => {
  try {
    const res = await api.post(
      `/customers/auth/create-password`,
      {
        phone: req.phone,
        password: req.password,
        confirm_password: req.confirm_password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return res.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  }
};

export const LOG_OUT = async (req) => {
  try {
    const res = await apiLogout.post(
      `${URL_BACKEND}/customers/auth/logout`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mToken")}`,
        },
      }
    );

    return res.data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  }
};
export const GET_ME = async (req) => {
  try {
    NProgress.start();
    const res = await api.get(`/customers/auth/get-me`);
    return res;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
      return error.response.data;
    } else if (error.request) {
      console.error("No response from server");
    } else {
      console.error("Error setting up request:", error.message);
    }
  } finally {
    NProgress.done();
  }
};
